import React from 'react';

import cx from 'classnames';

import css from './EventCard.module.css';

interface EventCardProps {
  children: React.ReactNode;
  postfix?: React.ReactNode;

  leftPadding?: boolean;
}

export const EventCard: React.FC<EventCardProps> = ({
  postfix,
  children,
  leftPadding = false,
}) => {
  return (
    <article className={cx(css.wrapper, { [css.wrapperPadded]: leftPadding })}>
      <div className={css.container}>{children}</div>

      {postfix ? <div className={css.postfixContainer}>{postfix}</div> : null}
    </article>
  );
};
