import React from 'react';

import Link from 'next/link';

import { Text } from '../../Text';

import css from './CardTitle.module.css';

import type { UrlObject } from 'url';

export interface CardTitleProps {
  title: string | undefined;
  link: UrlObject;
  onClick?: (event: React.MouseEvent) => void;
}

export const CardTitle = React.memo<CardTitleProps>(({ title, link, onClick }) => {
  if (!title) {
    return null;
  }

  return (
    <Link className={css.container} href={link} onClick={onClick}>
      <Text.R2 semibold className={css.text}>
        {title}
      </Text.R2>
    </Link>
  );
});
