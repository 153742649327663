import React, { useCallback, useEffect, useRef, useState } from 'react';

import cx from 'classnames';

import { api } from '../../../api';
import { utils } from '../../../utils';
import { Icon } from '../../Icons';
import { useText } from '../../Language';
import { LoaderBouncing } from '../../Loaders';
import { Text } from '../../Text';

import css from './UserLinkForm.module.css';

type ChangeIndexType = 'increment' | 'decrement';

interface UserLinkProps {
  index: number;
  link: api.UserLink;

  canIncrementIndex: boolean;

  onRequestUpdate: (link: api.UserLinkInput) => Promise<unknown> | unknown;
  onRequestChangeIndex: (type: ChangeIndexType) => Promise<unknown> | unknown;
  onRequestDelete: () => Promise<unknown> | unknown;
}

export const UserLinkForm = React.memo<UserLinkProps>(
  ({ link, index, onRequestUpdate, onRequestDelete }) => {
    const text = useText(state => state.userSettings.links);

    const [urlPreview, setUrlPreview] = useState<string>(link.url || '');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [titleError, setTitleError] = useState<boolean>(false);
    const [urlError, setUrlError] = useState<boolean>(false);

    const titleRef = useRef<HTMLInputElement | null>(null);
    const descRef = useRef<HTMLInputElement | null>(null);
    const urlRef = useRef<HTMLInputElement | null>(null);
    const ignoreBlur = useRef<boolean>(false);

    useEffect(() => {
      if (urlRef.current) {
        if (urlRef.current.value !== link.url) {
          urlRef.current.value = link.url;
        }
      }
      if (titleRef.current) {
        if (titleRef.current.value !== link.url) {
          titleRef.current.value = link.title || '';
        }
      }

      if (descRef.current) {
        if (descRef.current.value !== link.url) {
          descRef.current.value = link.description || '';
        }
      }
    }, [urlRef, titleRef, descRef, link]);

    const handleBlur = async () => {
      if (urlError || titleError) {
        return;
      }
      const title = titleRef.current?.value?.trim() || '';
      let url = urlRef.current?.value?.trim() || '';
      const description = descRef.current?.value?.trim() || '';

      if (!url.startsWith('https://') && !url.startsWith('http://')) {
        url = 'https://' + url;
      }

      if (!validateTitle(title)) {
        return setTitleError(true);
      }
      if (!validateUrl(url)) {
        return setUrlError(true);
      }

      const changes: api.UserLinkInput = {
        ...(title !== link.title && { title }),
        ...(url !== link.url && { url }),
        ...(description !== link.description && { description }),
      };

      if (Object.keys(changes).length === 0) {
        return;
      }

      setIsLoading(true);
      await onRequestUpdate(changes);
      setIsLoading(false);
    };

    const validateUrl = useCallback(isValidLink, []);
    const validateTitle = useCallback((str: string) => str && str.length <= 80, []);

    return (
      <div
        className={cx(css.container, {
          [css.containerError]: urlError || titleError,
        })}
        onBlur={async () => {
          if (ignoreBlur.current) {
            return;
          }

          const elements = [titleRef.current, descRef.current, urlRef.current];

          // check all inputs not to be focused
          await utils.delay(100);
          if (ignoreBlur.current) {
            return;
          }
          const isFocused = elements.includes(
            document.activeElement as HTMLInputElement | null,
          );

          if (!isFocused && !ignoreBlur.current) {
            // console.log('SUBMITTING');
            handleBlur();
          }
        }}
      >
        <article className={css.indexWrapper}>
          <Text.Subtitle className={css.indexText}>{index + 1}.</Text.Subtitle>

          <div className={css.indexButtonsContainer}>
            {/* <button
              disabled={index === 0}
              className={css.indexButton}
              onClick={() => onRequestChangeIndex('decrement')}
            >
              <Icon.ArrowUp />
            </button>
            <button
              disabled={canIncrementIndex}
              className={css.indexButton}
              onClick={() => onRequestChangeIndex('increment')}
            >
              <Icon.ArrowDown />
            </button>
            <button className={css.indexButton} onClick={onRequestDelete}>
              <Icon.Close />
            </button> */}
          </div>
        </article>

        <section className={css.wrapper}>
          <article className={css.titleWrapper}>
            <SiteFavicon url={urlPreview || DEFAULT_FAVICON} />
            <input
              ref={titleRef}
              className={cx(css.input, { [css.invalidInput]: titleError })}
              defaultValue={link.title}
              maxLength={80}
              placeholder={link.title || text.title.placeholder}
              onBlur={event => {
                const value = event.target.value;
                const isValid = validateTitle(value);

                if (!isValid) {
                  setTitleError(true);
                }
              }}
              onFocus={() => setTitleError(false)}
            />
            <Icon.Trash
              className={css.deleteIconTemp}
              tabIndex={-1}
              onClick={event => {
                event.preventDefault();

                titleRef.current?.focus();
                ignoreBlur.current = true;
                onRequestDelete();
              }}
            />
          </article>
          <input
            ref={urlRef}
            className={cx(css.input, { [css.invalidInput]: urlError })}
            defaultValue={link.url}
            placeholder={link.url || text.link.placeholder}
            onBlur={event => {
              const value = event.target.value;
              const isValid = validateUrl(value);
              if (isValid) {
                setUrlPreview(value);
              } else {
                setUrlError(true);
              }
            }}
            onFocus={() => setUrlError(false)}
          />
          <input
            ref={descRef}
            className={css.input}
            defaultValue={link.description}
            maxLength={255}
            placeholder={link.description || text.description.placeholder}
          />
          <div
            className={cx(css.loadingContainer, {
              [css.loadingVisibleContainer]: isLoading,
            })}
          >
            <LoaderBouncing />
          </div>
        </section>
      </div>
    );
  },
);

export const DEFAULT_FAVICON = 'lf.group';
const FAVICON_SIZE = 64;

export function getFaviconUrl(url: string): string {
  const req = new URL('https://s2.googleusercontent.com/s2/favicons');
  req.searchParams.append('sz', FAVICON_SIZE.toString());
  req.searchParams.append('domain', encodeURI(url));

  return req.toString();
}

interface SiteFaviconProps {
  url: string;
}

const SiteFavicon = React.memo<SiteFaviconProps>(({ url }) => {
  const ref = useRef<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const imgUrl = getFaviconUrl(url);

  useEffect(() => {
    if (ref.current === '') {
      ref.current = url;
    }
    if (ref.current !== url) {
      setIsLoading(true);
    }
  }, [url]);

  return (
    <div className={css.iconContainer}>
      <img
        className={css.iconImage}
        src={imgUrl}
        onLoad={() => setIsLoading(false)}
      />
      <LoaderBouncing
        className={cx(css.iconLoader, { [css.iconLoaderVisible]: isLoading })}
        size={24}
      />
    </div>
  );
});

function isValidLink(str: string): boolean {
  const res = str.match(
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g,
  );

  return res !== null;
}
