import React from 'react';

import { api } from 'api';

import { EventCard } from './EventCard';
import css from './EventWrapper.module.css';
import { EventWrapperAuthorUser } from './EventWrapperAuthor';

interface AuthorUserProps {
  author: api.User;
}

export type EventWrapperProps = {
  children: React.ReactNode;

  description?: string;

  onAuthorClick?: (event: React.MouseEvent) => unknown | Promise<unknown>;
  onCopyDiscord?: (event: React.MouseEvent) => unknown | Promise<unknown>;
  renderActions?: () => React.ReactElement | null;
} & AuthorUserProps;

export const EventWrapper: React.FC<EventWrapperProps> = ({
  onAuthorClick,
  children,
  description,
  renderActions,
  ...props
}) => {
  const renderAuthor = (): React.ReactNode => {
    return (
      <EventWrapperAuthorUser
        description={description}
        renderActions={renderActions}
        user={props.author}
        onAuthorClick={onAuthorClick}
        onCopyDiscord={props.onCopyDiscord}
      />
    );
  };

  return (
    <div className={css.container}>
      {renderAuthor()}
      <EventCard leftPadding>{children}</EventCard>
    </div>
  );
};
