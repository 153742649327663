import React from 'react';

import { api } from 'api';

import { Desktop } from './Desktop';
import { EventAction } from './helpers';
import { Mobile } from './Mobile';

export interface PostEventProps {
  event: api.Event;

  isEventOwner: boolean;
  isMobile: boolean;

  onRequestAction: (
    action: EventAction,
    event: React.MouseEvent,
  ) => Promise<void> | void;
}

export const PostEvent: React.FC<PostEventProps> = ({
  event,
  onRequestAction,
  isMobile,
  isEventOwner,
}) => {
  if (isMobile) {
    return (
      <Mobile
        event={event}
        isEventOwner={isEventOwner}
        isMobile={isMobile}
        onRequestAction={onRequestAction}
      />
    );
  }

  return (
    <Desktop
      event={event}
      isEventOwner={isEventOwner}
      isMobile={isMobile}
      onRequestAction={onRequestAction}
    />
  );
};
