import React, { useMemo, useRef } from 'react';

import cx from 'classnames';
import Image from 'next/image';
import Link from 'next/link';

import { api } from 'api';
import { Avatar } from 'components/Avatar';
import { CardDescription } from 'components/Card';
import { EventTimestamp } from 'components/Event';
import { Icon } from 'components/Icons';
import { GameIcon } from 'components/Icons__deprecated';
import { Text } from 'components/Text';
import { useText } from 'text';
import { links } from 'utils/links';

import { PostEventProps } from './Container';
import css from './Desktop.module.css';
import { EventActionList } from './EventAction';
import { EventTagList } from './EventTag';
import { formatInt, countMembers } from './helpers';

export const Desktop: React.FC<PostEventProps> = ({
  event,
  onRequestAction,
  isEventOwner,
}) => {
  const text = useText(state => state.organization);
  const link = links.event(event.owner, event.id);

  const viewsCount = useMemo((): string => {
    const views = formatInt(event?.views);

    return text.page.commonViews(views);
  }, [event?.views]);

  const membersCount = useMemo((): string => {
    const count = countMembers(event);
    if (!count) {
      return '';
    }

    return text.page.joinedMemberCount(count as any);
  }, [event.type, event.slotsCount]);

  const renderThumbnail = (): React.ReactNode => {
    if (!event) {
      return null;
    }

    const ownerName = event.owner.username;
    const ownerLink = links.organization.home(ownerName);

    const isVerified = event.owner.roles?.includes(api.UserRole.Streamer);

    return (
      <section className={css.thumbnailContainer}>
        <div className={css.thumbailWrapper}>
          <Link className={css.thumbnailLink} href={ownerLink}>
            <Text.R2 semibold className={css.thumbnailAuthorName}>
              @{ownerName}
            </Text.R2>
          </Link>
          {isVerified && <Icon.StreamerCheck className={css.thumbnailVerifiedIcon} />}
        </div>

        <GameIcon className={css.thumbnailGameIcon} gameId={event.gameId} size={40} />
      </section>
    );
  };

  const renderAvatar = (): React.ReactNode => {
    if (!event) {
      return null;
    }

    const avatarUrl = event.owner.avatarUrl;
    const ownerName = event.owner.username;
    const ownerLink = links.organization.home(ownerName);

    return (
      <Link href={ownerLink}>
        <Avatar
          className={css.thumbnailAvatar}
          online={event.owner.online}
          size={128}
          url={avatarUrl}
        />
      </Link>
    );
  };

  const renderCalendar = (): React.ReactNode => {
    if (!event) {
      return null;
    }

    return (
      <div className={css.calendarContainer}>
        <EventTimestamp
          withIcon
          endTime={event.scheduledTo}
          format="dd.MM"
          hideStartDay={false}
          startTime={event.scheduledAt || event.createdAt}
          TextFormatter={Text.R2Memo}
        />
      </div>
    );
  };

  const renderEventName = (): React.ReactNode => {
    if (!event?.name) {
      return null;
    }

    if (event.type === api.EventType.Post) {
      return null;
    }

    return (
      <Link className={css.titleContainer} href={link}>
        {event.name}
      </Link>
    );
  };

  const renderEventDescription = (): React.ReactNode => {
    if (!event?.description) {
      return null;
    }

    return (
      <div className={css.descriptionContainer}>
        <CardDescription
          className={css.descriptionText}
          description={event.description}
          maxCharacters={160}
          messageClassName={cx({
            [css.descriptionPostMessage]: event.type === api.EventType.Post,
          })}
          TextFormatter={Text.R2Memo}
        />
      </div>
    );
  };

  const renderTags = (): React.ReactNode => {
    if (!event) {
      return null;
    }

    return (
      <section className={css.tagsContainer}>
        <EventTagList event={event} TextFormatter={Text.R2Memo} />
      </section>
    );
  };

  const ref = useRef<string>('');

  const renderPreview = (): React.ReactNode => {
    if (!event.backgroundUrl) {
      return null;
    }
    if (!ref.current) {
      ref.current = event.backgroundUrl;
    }

    return (
      <Link className={css.previewContainer} href={link}>
        <Image
          fill
          priority
          alt={event.name}
          className={css.previewImage}
          sizes="100%"
          src={ref.current}
        />
      </Link>
    );
  };

  const renderMembers = (): React.ReactNode => {
    if (!event) {
      return null;
    }

    return (
      <section className={css.membersContainer}>
        <Text.R3 className={css.membersText}>&nbsp;{viewsCount}</Text.R3>
        {membersCount && event.type !== api.EventType.Post && (
          <Text.R3 className={css.membersText}>&nbsp;·&nbsp;{membersCount}</Text.R3>
        )}
      </section>
    );
  };

  const renderActions = (): React.ReactNode => {
    if (!event) {
      return null;
    }

    return (
      <section className={css.actionsContainer}>
        <EventActionList
          event={event}
          isOwner={isEventOwner}
          onRequestAction={onRequestAction}
        />
      </section>
    );
  };

  const renderContent = (): React.ReactNode => {
    const content = (
      <>
        {renderCalendar()}
        {renderEventName()}
        {renderEventDescription()}
        {renderTags()}
        {renderPreview()}
        {renderMembers()}
      </>
    );

    if (event.type === api.EventType.Post) {
      return <Link href={link}>{content}</Link>;
    }

    return content;
  };

  return (
    <section className={css.container}>
      <aside className={css.sideContainer}>{renderAvatar()}</aside>
      <article className={css.wrapper}>
        {renderThumbnail()}
        {renderContent()}
        {renderActions()}
      </article>
    </section>
  );
};
