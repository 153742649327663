import React from 'react';

import { isFuture, isPast } from 'date-fns';

import { api } from 'api';

import { useApp } from 'components/ContextApp';
import { Icon } from 'components/Icons';

import { useText } from '../../Language';

import { EventAction as Action } from '../helpers';

import { EventAction } from './EventAction';

interface EventTagListProps {
  event: api.Event;
  isOwner: boolean;

  onRequestAction?: (
    action: Action,
    event: React.MouseEvent,
  ) => Promise<unknown> | unknown;
}

export const EventActionList: React.FC<EventTagListProps> = ({
  event,
  isOwner,
  onRequestAction = () => undefined,
}) => {
  const text = useText(state => state.event);
  const textShare = useText(state => state.controls.actions);
  const textChat = useText(state => state.chat);

  const host = event.owner;
  const isFollowing = host?.isFollowing || false;

  const { isMobile } = useApp();

  const isMember = Boolean(event.userSlot);

  const getJoinType = (): api.SlotType => {
    switch (event.type) {
      case api.EventType.Giveaway:
        return api.SlotType.Waitlist;
      default:
        return api.SlotType.Member;
    }
  };

  const renderJoinButton = (): React.ReactNode => {
    if ([api.EventType.Post].includes(event.type)) {
      if (!(event.links?.websiteTitle && event.links?.websiteUrl)) {
        return null;
      }

      return (
        <EventAction
          color="green-20"
          disabled={isOwner}
          Icon={Icon.Viewers}
          linkUrl={event.links.websiteUrl}
          text={event.links?.websiteTitle}
          onClick={() => undefined}
        />
      );
    }

    if (
      [api.EventType.Public, api.EventType.Waitlist, api.EventType.Link].includes(
        event.type,
      ) ||
      (event.type === api.EventType.Event && event.hasWaitlist)
    ) {
      return (
        <EventAction
          color="green-20"
          disabled={isOwner}
          Icon={Icon.Viewers}
          text={text.actions.viewAction}
          onClick={event => onRequestAction({ type: 'OPEN_EVENT' }, event)}
        />
      );
    }

    if (isMember) {
      // Temporary while logic changes
      if (2 > 1) {
        return (
          <EventAction
            color="green-20"
            Icon={Icon.Viewers}
            text={text.actions.viewAction}
            onClick={event => onRequestAction({ type: 'OPEN_EVENT' }, event)}
          />
        );
      }

      if (
        isMobile &&
        event.gameId === api.GameId.PubgMobile &&
        event.type !== api.EventType.Giveaway
      ) {
        return (
          <EventAction
            color="green-20"
            Icon={Icon.Dungeon}
            text={text.actions.play.playAction}
            onClick={event => onRequestAction({ type: 'PLAY' }, event)}
          />
        );
      }

      return (
        <EventAction
          color="yellow-20"
          disabled={isOwner}
          Icon={Icon.CircleAccept}
          text={text.actions.join.joined}
          onClick={event => onRequestAction({ type: 'LEAVE' }, event)}
        />
      );
    }

    if (
      event.type === api.EventType.Giveaway &&
      (isFuture(new Date(event.scheduledAt || event.createdAt)) ||
        (event.scheduledTo && isPast(new Date(event.scheduledTo))))
    ) {
      const textFollow = isFollowing
        ? text.actions.giveaway.subscribed
        : text.actions.giveaway.notify;

      return (
        <EventAction
          color="green-20"
          disabled={isOwner}
          Icon={isFollowing ? Icon.UserFollowed : Icon.UserAdd}
          text={textFollow}
          onClick={event => onRequestAction({ type: 'FOLLOW_HOST' }, event)}
        />
      );
    }

    return (
      <EventAction
        color="green-20"
        disabled={isOwner}
        Icon={Icon.CircleAdd}
        text={text.actions.join.joinAction}
        onClick={event =>
          onRequestAction({ type: 'JOIN', slotType: getJoinType() }, event)
        }
      />
    );
  };

  return (
    <>
      {renderJoinButton()}

      <EventAction
        Icon={Icon.Heart}
        iconColor={event.isLiked ? 'var(--red)' : undefined}
        text={`${event.likesCount || 0}`}
        onClick={event => onRequestAction({ type: 'LIKE' }, event)}
      />
      <EventAction
        Icon={Icon.Share}
        text={textShare.share}
        onClick={event => onRequestAction({ type: 'SHARE' }, event)}
      />
      <EventAction
        Icon={Icon.Chats}
        text={textChat.title}
        onClick={event =>
          onRequestAction({ type: isMobile ? 'CHAT' : 'OPEN_EVENT' }, event)
        }
      />
    </>
  );
};
