import React from 'react';

import cx from 'classnames';

import { Button } from '../Button';

import { useText } from '../Language';
import { Text } from '../Text';

import css from './Error.module.css';

interface ErrorProps {
  children?: React.ReactNode;
  actionText?: string;
  onAction?: (event: React.MouseEvent) => void;
  className?: string;
  subtext?: string;
}

export const Error = React.memo<ErrorProps>(
  ({ children, actionText, className, onAction = () => undefined, subtext }) => {
    return (
      <article className={cx(css.container, className)}>
        <Text.H2 className={css.text}>{children}</Text.H2>
        {subtext ? (
          <Text.Regular className={css.subtext}>{subtext}</Text.Regular>
        ) : null}
        {actionText ? (
          <Button className={css.button} color="secondary" onClick={onAction}>
            {actionText}
          </Button>
        ) : null}
      </article>
    );
  },
);

export const ErrorNotFound: React.FC<{
  onAction?: (event: React.MouseEvent) => void;
}> = ({ onAction = () => undefined }) => {
  const text = useText(state => state.controls.errors[404]);

  return (
    <Error actionText={text.action} onAction={onAction}>
      {text.text}
    </Error>
  );
};
