import React from 'react';

import css from './EventTag.module.css';

interface EventTagProps {
  children: React.ReactNode;
}
export const EventTag: React.FC<EventTagProps> = ({ children }) => {
  return <div className={css.container}>{children}</div>;
};
