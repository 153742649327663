import React, { forwardRef, useId, useState } from 'react';

import cx from 'classnames';

import { getGridSizeStyle } from 'components/Form/FormGrid';
import { Text } from 'components/Text';

import css from './FormInput.module.css';

export type TextAreaProps = React.DetailedHTMLProps<
  React.TextareaHTMLAttributes<HTMLTextAreaElement>,
  HTMLTextAreaElement
>;

export interface FormTextAreaProps extends TextAreaProps {
  label: string;
  error?: string;
  gridSize?: number;
  maxLength?: number;
}

export const FormTextArea = forwardRef<HTMLTextAreaElement, FormTextAreaProps>(
  ({ label, error, className, id, gridSize, ...props }, ref) => {
    const [isFocused, setIsFocused] = useState<boolean>(props.autoFocus || false);
    const defaultId = useId();

    // "0" || 0 are valid values
    const isEmpty = props.value === undefined || props.value === '';

    const labelText = label + (error ? ` (${error})` : '');

    return (
      <div style={getGridSizeStyle(gridSize)}>
        <div
          className={cx(
            css.container,
            { [css.containerFocused]: isFocused },
            className,
          )}
        >
          <Text.R2
            Tag="label"
            {...{ htmlFor: id }}
            className={cx(css.label, {
              [css.labelSmall]: isFocused || !isEmpty,
              'text-sm': isFocused || !isEmpty,
              [css.labelError]: Boolean(error),
              [css.labelRequired]: props.required,
            })}
          >
            {labelText}
          </Text.R2>
          <textarea
            ref={ref}
            className={cx('text-lg', css.input, css.textArea, className)}
            maxLength={props.maxLength}
            {...props}
            autoComplete="off"
            id={id || defaultId}
            value={props.value || ''}
            onBlur={event => {
              setIsFocused(false);
              if (props.onBlur) {
                props.onBlur(event);
              }
            }}
            onFocus={event => {
              setIsFocused(true);
              if (props.onFocus) {
                props.onFocus(event);
              }
            }}
          />
        </div>
      </div>
    );
  },
);
