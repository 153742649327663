import React, { forwardRef, useState } from 'react';

import cx from 'classnames';

import { Icon } from '../Icons';
import { Text } from '../Text';

import css from './FormInput.module.css';

export type InputProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;

export interface FormInputWithIconProps extends InputProps {
  Icon: Icon.IconType;
  label: string;
  error?: string;
  inputWrapClassName?: string;
  iconClassName?: string;
}

export const FormInputWithIcon = forwardRef<HTMLInputElement, FormInputWithIconProps>(
  (
    {
      Icon,
      label,
      error,
      inputWrapClassName,
      className,
      id = label,
      iconClassName,
      ...props
    },
    ref,
  ) => {
    const [isFocused, setIsFocused] = useState<boolean>(props.autoFocus || false);

    return (
      <div>
        <div
          className={cx(
            css.container,
            css.inputContainer,
            css.containerWithIcon,
            { [css.containerFocused]: isFocused },
            inputWrapClassName,

            className,
          )}
        >
          <div className={css.iconContainer}>
            <Icon className={cx(css.inputIcon, iconClassName)} />
          </div>
          <input
            ref={ref}
            className={cx('text-lg', css.input, css.inputWithIcon, className)}
            {...props}
            autoComplete="off"
            id={id}
            placeholder={label}
            value={props.value}
            onBlur={event => {
              setIsFocused(false);
              if (props.onBlur) {
                props.onBlur(event);
              }
            }}
            onFocus={event => {
              setIsFocused(true);
              if (props.onFocus) {
                props.onFocus(event);
              }
            }}
          />
        </div>
        {error ? (
          <Text.Regular className={css.inputError} size={3}>
            {error}
          </Text.Regular>
        ) : null}
      </div>
    );
  },
);
