import React, { useCallback, useState, useMemo } from 'react';

import cx from 'classnames';

import { useMe } from 'api';
import { ButtonIcon } from 'components/Button';
import { EventCard, EventWrapper } from 'components/EventWrapper';
import { Icon } from 'components/Icons';
import { GameIcon } from 'components/Icons__deprecated';
import { Text } from 'components/Text';
import { useTranslations } from 'text';

import { CardTags, CardDescription } from '../Card';

import css from './[Game]ProfileCard.module.css';

import type { GameProfileCardProps } from './[Game]ProfileCard';

export interface GameProfileCardTemplateProps extends GameProfileCardProps {
  topContent?: React.ReactNode;
  bottomContent?: React.ReactNode;
  isEmptyCard?: boolean;
}

export const GameProfileCardTemplate = React.memo<GameProfileCardTemplateProps>(
  ({
    user,
    profile,
    selectedTags = [],
    topContent = null,
    bottomContent = null,
    hideUser = false,
    isEmptyCard = true,
    isMobile = false,
    onTagClick,
    onUserClick = () => undefined,
    onCopyDiscordClick = () => undefined,
    renderActions = () => null,
    onRequestEditLfg,
  }) => {
    const translation = useTranslations();
    const me = useMe();

    const isMyProfile = useMemo(() => me?.id === user.id, [me, user]);

    const isMobileProfile = isMobile && hideUser;

    const [isOpen, setIsOpen] = useState(false);

    const handleUserClick = useCallback(
      (event: React.MouseEvent) => onUserClick(user, event),
      [onUserClick, user],
    );
    const handleCopyDiscordClick = useCallback(
      (event: React.MouseEvent) => onCopyDiscordClick(user, event),
      [onUserClick, user.id],
    );
    const handleActions = useCallback(
      () => renderActions(user, profile),
      [renderActions, user, profile],
    );

    const renderContent = (): React.ReactNode => {
      return (
        <>
          {topContent ? (
            <section className={css.topContainer}>{topContent}</section>
          ) : null}
          {profile.description || profile.tags?.length ? (
            <section className={css.container}>
              <CardDescription description={profile?.description} />
              <CardTags
                selectedTags={selectedTags}
                tags={profile.tags || []}
                onTagClick={onTagClick}
              />
            </section>
          ) : null}
          {bottomContent ? (
            <section className={css.bottomContainer}>{bottomContent}</section>
          ) : null}
        </>
      );
    };

    const renderArrow = (): React.ReactNode => {
      if (!(profile.description || profile.tags?.length || bottomContent)) {
        return null;
      }

      if (isOpen) {
        return (
          <ButtonIcon
            className={css.arrowDown}
            Icon={Icon.ArrowUp}
            iconClassName={css.arrowDownIcon}
          />
        );
      }

      return (
        <ButtonIcon
          className={css.arrowDown}
          Icon={Icon.ArrowDown}
          iconClassName={css.arrowDownIcon}
        />
      );
    };

    if (isMobileProfile) {
      return (
        <div className={css.noUserProfileCard} onClick={() => setIsOpen(!isOpen)}>
          <div className={css.visibleProfileCardContent}>
            <GameIcon
              className={css.emptyProfileHeaderIcon}
              gameId={profile.gameId}
              size={40}
            />
            <div className={css.profileCardContent}>
              <Text.R3 semibold>{translation.gameTitle(profile.gameId).full}</Text.R3>
              {topContent ? (
                <section className={cx(css.topContainer, css.profileTopContainer)}>
                  {topContent}
                </section>
              ) : null}
            </div>
            <div className={css.arrowsWrapper}>
              {isMyProfile && onRequestEditLfg && (
                <ButtonIcon
                  className={css.arrowDown}
                  Icon={Icon.Edit}
                  iconClassName={cx(css.arrowDownIcon, css.editIcon)}
                  onClick={event => {
                    event.stopPropagation();
                    onRequestEditLfg(profile.gameId);
                  }}
                />
              )}
              {renderArrow()}
            </div>
          </div>
          {(profile.description || profile.tags?.length || bottomContent) && (
            <div
              className={css.hiddenProfileCardContent}
              style={{
                visibility: isOpen ? 'visible' : 'hidden',
                maxHeight: isOpen ? 500 : 0,
                opacity: isOpen ? 1 : 0,
                marginTop: isOpen ? 12 : 0,
              }}
            >
              {bottomContent && (
                <section
                  className={cx(css.bottomContainer, css.profileBottomContainer)}
                >
                  {bottomContent}
                </section>
              )}
              {profile.description && (
                <CardDescription
                  className={css.description}
                  description={profile?.description}
                />
              )}
              {profile.tags?.length && (
                <CardTags
                  selectedTags={selectedTags}
                  tags={profile.tags || []}
                  onTagClick={onTagClick}
                />
              )}
            </div>
          )}
        </div>
      );
    }

    if (isEmptyCard) {
      if (hideUser) {
        return (
          <div className={css.emptyProfileCard}>
            <GameIcon
              className={css.emptyProfileHeaderIcon}
              gameId={profile.gameId}
              size={40}
            />
            <Text.R3 semibold>{translation.gameTitle(profile.gameId).full}</Text.R3>
          </div>
        );
      }

      return (
        <EventWrapper
          author={user}
          renderActions={handleActions}
          onAuthorClick={handleUserClick}
          onCopyDiscord={handleCopyDiscordClick}
        >
          <div className={css.emptyProfileCard}>
            <GameIcon
              className={css.emptyProfileHeaderIcon}
              gameId={profile.gameId}
              size={40}
            />
            <Text.R3 semibold>{translation.gameTitle(profile.gameId).full}</Text.R3>
          </div>
        </EventWrapper>
      );
    }

    if (hideUser) {
      return (
        <div>
          <span className={css.profileHeaderContainer}>
            <GameIcon
              className={css.profileHeaderIcon}
              gameId={profile.gameId}
              size={40}
            />
            <Text.R3 semibold>{translation.gameTitle(profile.gameId).full}</Text.R3>
          </span>
          <EventCard>{renderContent()}</EventCard>
        </div>
      );
    }

    return (
      <EventWrapper
        author={user}
        renderActions={handleActions}
        onAuthorClick={handleUserClick}
        onCopyDiscord={handleCopyDiscordClick}
      >
        {renderContent()}
      </EventWrapper>
    );
  },
);
