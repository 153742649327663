import { useMemo } from 'react';

import { makeVar } from '@apollo/client';

const highlightedGames = makeVar<string[]>([]);
const set = (ids: string[]) => {
  return highlightedGames(ids);
};

export function useHighlightedGames(
  id?: string,
): [highlighted: boolean, set: (ids: string[]) => void] {
  const values = highlightedGames();

  const isIncluded = useMemo(() => {
    if (!id) {
      return false;
    }

    return values.includes(id);
  }, [id, values]);

  return [isIncluded, set];
}
