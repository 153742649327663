import React, { useMemo } from 'react';

import cx from 'classnames';
import { add, isBefore, isSameDay } from 'date-fns';

import { Calendar } from 'components/Icons/Icons';
import { Text } from 'components/Text';
import { useDateFormatter } from 'utils/date';

import css from './EventTimestamp.module.css';

interface EventTimestampProps {
  startTime: string;
  endTime?: string;
  withIcon?: boolean;
  hideStartDay?: boolean;
  small?: boolean;
  format?: string;
  className?: string;

  TextFormatter?: typeof Text.R3Memo;
}
export const EventTimestamp = React.memo<EventTimestampProps>(
  ({
    startTime,
    endTime,
    withIcon,
    format,
    hideStartDay,
    small,
    className,
    TextFormatter = Text.R3Memo,
  }) => {
    // currently if started more than hour ago
    const isPast = useMemo(() => {
      if (endTime) {
        return isBefore(new Date(endTime), new Date());
      }
      const hourAgo = add(new Date(startTime), { hours: 1 });

      return isBefore(hourAgo, new Date(startTime));
    }, [startTime, endTime]);

    const shouldDisplayStartDay = useMemo(() => {
      if (!hideStartDay) {
        return false;
      }

      if (!endTime) {
        return true;
      }

      return isSameDay(new Date(startTime), new Date(endTime));
    }, [startTime, endTime, hideStartDay]);

    const startDate = useDateFormatter(startTime, {
      onlyTime: shouldDisplayStartDay,
      format,
    });
    const endDate = useDateFormatter(endTime, {
      onlyTime: isSameDay(
        new Date(startTime),
        endTime ? new Date(endTime) : new Date(),
      ),
      format,
    });

    return (
      <div className={cx(css.container, className, { [css.containerPast]: isPast })}>
        {withIcon ? <Calendar className={css.icon} /> : null}
        <TextFormatter className={cx(css.text, { [css.smallText]: small })}>
          {startDate}
        </TextFormatter>
        {endTime ? (
          <>
            <TextFormatter className={cx(css.text, { [css.smallText]: small })}>
              &nbsp;—&nbsp;
            </TextFormatter>
            <TextFormatter className={cx(css.text, { [css.smallText]: small })}>
              {endDate}
            </TextFormatter>
          </>
        ) : null}
      </div>
    );
  },
);
