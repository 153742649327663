import React from 'react';

import cx from 'classnames';

import css from './FormGrid.module.css';

export interface FormGridProps {
  grid?: number;
  children: React.ReactNode | React.ReactNode[];
  clasName?: string;
}

export const FormGrid: React.FC<FormGridProps> = ({
  grid = 4,
  clasName,
  children,
}) => {
  return (
    <section
      className={cx(css.container, clasName)}
      style={{ gridTemplateColumns: `repeat(${grid}, 1fr)` }}
    >
      {children}
    </section>
  );
};
