import React, { useMemo } from 'react';

import cx from 'classnames';

import { api } from 'api';

import { Icon } from 'components/Icons';
import {
  LolDuoIcon,
  LolJungleIcon,
  LolMidIcon,
  LolSoloIcon,
  LolSupportIcon,
  UnsetIcon,
} from 'components/Icons/GameIcons';

import css from './LolChampionAvatarList.module.css';

interface LolChampionAvatarListProps {
  champions?: api.LolChampion[];
  role?: api.SlotRole;
  size?: 24 | 32 | 48 | 100;
}

export const LolChampionAvatarList: React.FC<LolChampionAvatarListProps> = React.memo(
  ({ champions, role, size = 32 }) => {
    const intersection = useMemo(() => {
      switch (size) {
        case 24:
          return 8;
        case 32:
          return 10;
        case 48:
          return 20;
        case 100:
          return 42;
      }
    }, [size]);

    const RoleIcon = useMemo(() => {
      switch (role) {
        case api.SlotRole.LolDuo:
          return LolDuoIcon;
        case api.SlotRole.LolJungle:
          return LolJungleIcon;
        case api.SlotRole.LolMid:
          return LolMidIcon;
        case api.SlotRole.LolSolo:
          return LolSoloIcon;
        case api.SlotRole.LolSupport:
          return LolSupportIcon;
        default:
          return UnsetIcon;
      }
    }, [role]);

    if (!champions) {
      return null;
    }

    return (
      <div className={css.lolChampionsContainer}>
        {RoleIcon ? (
          <div style={{ zIndex: 5 }}>
            <Icon.Rounded
              className={cx(css.lolChampionAvatar)}
              Icon={RoleIcon}
              iconStyle={{ width: size - 0.3 * size, height: size - 0.3 * size }}
              style={{ width: size, height: size }}
            />
          </div>
        ) : null}

        {champions.map((champion, index) => (
          <div
            key={index}
            className={cx(css.lolChampionAvatar)}
            style={{
              zIndex: 4 - index,
              // intersection between champion icons
              marginLeft: `${-1 * intersection}px`,
              backgroundImage: `url("${champion.icon}")`,
              width: size,
              height: size,
            }}
          />
        ))}
      </div>
    );
  },
);
