import React from 'react';

import Link from 'next/link';

import { Button } from '../Button';

import { useText } from '../Language';
import { Logo } from '../Logo';
import { Text } from '../Text';

import css from './Error.module.css';

export const ErrorPage = React.memo(() => {
  const text = useText(state => state.home.page404);

  return (
    <div className={css.containerPage}>
      <img className={css.pageImage} height={200} src={text.gif} />
      <Logo height={96} width={96} />
      <Text.H1 className={css.textPage}>{text.title}</Text.H1>
      <span className={css.buttonPage}>
        <Link href="/">
          <Button className={css.errorButton}>{text.actionGoToMain}</Button>
        </Link>
      </span>
    </div>
  );
});
