import React, { useMemo } from 'react';

import { api } from 'api';

import { CardParam, CardParams, CardParamsTable } from 'components/Card';
import { ProfileInfoWow } from 'components/ProfileCardList/ProfileInfoGames/ProfileInfoWow';
import { useTranslations, useText } from 'text';
import { filterGames } from 'utils/main';

import { GameProfileCardTemplate } from '../Template';

import type { GameProfileCardProps } from '../[Game]ProfileCard';

export const WowProfileCard: React.FC<GameProfileCardProps> = ({ ...props }) => {
  const text = useText(state => state.gameData.wow);
  const translations = useTranslations();

  const profile = props.profile;
  const extended = props.extended;
  const games = filterGames<api.Wow>(
    api.GameId.WorldOfWarcraft,
    profile?.games || [],
  );

  const isMobileProfile = props.isMobile && props.hideUser;

  const params = useMemo<CardParam[]>(() => {
    const params: CardParam[] = [];

    if (profile?.wow?.region) {
      const region = profile.wow.region;

      params.push({
        title: text.wowRegion.title,
        value: translations.wow.wowRegion(region),
      });
    }

    return params;
  }, [profile?.wow?.region]);

  const topContent = (): React.ReactNode => {
    if (!params.length) {
      return null;
    }

    if (isMobileProfile) {
      return <CardParams values={params} />;
    }

    return <CardParamsTable values={params} />;
  };

  const bottomContent = (): React.ReactNode => {
    if (!games.length) {
      return null;
    }

    return (
      <ProfileInfoWow
        isMobileProfile={isMobileProfile}
        lfg={profile}
        showAllChars={extended}
        username={props.user.username}
      />
    );
  };

  const isEmptyProfile = [...params].length === 0 && !games.length;

  return (
    <GameProfileCardTemplate
      {...props}
      bottomContent={bottomContent()}
      isEmptyCard={isEmptyProfile}
      topContent={topContent()}
    />
  );
};
