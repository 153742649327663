import React from 'react';

import { api } from 'api';
import { FormInput } from 'components/FormInput';
import { useText } from 'text';

interface AliasProps {
  gameId: api.GameId;
  value: string;
  onChange: (value: string) => void;

  required?: boolean;
  gridSize?: number;
  className?: string;
}

export const FormAlias: React.FC<AliasProps> = ({
  gameId,
  value,
  onChange,
  gridSize,
  className,
  required = false,
}) => {
  const alias = useLabelAliasText(gameId);

  return (
    <FormInput
      className={className}
      gridSize={gridSize}
      label={alias}
      required={required}
      value={value}
      onChange={event => onChange(event.target.value)}
    />
  );
};

interface AccountIdProps {
  value: string;
  onChange: (value: string) => void;

  required?: boolean;
  gridSize?: number;
  className?: string;
}

export const FormAccountId: React.FC<AccountIdProps> = ({
  value,
  onChange,
  gridSize,
  className,
  required = false,
}) => {
  return (
    <FormInput
      className={className}
      gridSize={gridSize}
      label="Game ID"
      required={required}
      value={value}
      onChange={event => onChange(event.target.value)}
    />
  );
};

export function useLabelAliasText(gameId: api.GameId) {
  const text = useText(state => state.gameData);

  switch (gameId) {
    case api.GameId.Csgo:
      return text.csgo.alias.title;
    case api.GameId.PubgMobile:
      return text.pubg.nickname.title;
    case api.GameId.CodMobile:
      return text.cod.nickname.title;
    case api.GameId.GooseGooseDuck:
      return text.ggd.alias.title;
    case api.GameId.Hearthstone:
      return text.hearthstone.alias.title;
    case api.GameId.Standoff2:
      return text.standoff2.nickname.title;
    default:
      return text.warzone.fields.login;
  }
}
