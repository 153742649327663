import React from 'react';

import { api, LfgTag } from 'api';

import { AnyProfileCard } from './Any';
import { CodMobileProfileCard } from './CodMobile';
import { CsgoProfileCard } from './Csgo';
import { LolProfileCard } from './Lol';
import { LostArkProfileCard } from './LostArk';
import { PubgMobileProfileCard } from './PubgMobile';
import { Standoff2ProfileCard } from './Standoff2';
import { WowProfileCard } from './Wow';

import type { SupportedGameId } from 'api/types';

export interface GameProfileCardProps {
  profile: api.Lfg;
  user: api.User;
  extended?: boolean;
  hideUser?: boolean;
  isMobile?: boolean;
  selectedTags?: string[];
  onTagClick?: (tag: LfgTag, event: React.MouseEvent) => void;

  renderActions?: (user: api.User, profile: api.Lfg) => React.ReactElement | null;
  onRequestEditLfg?: (gameId: api.GameId) => Promise<void> | void;
  onUserClick?: (
    user: api.User,
    event: React.MouseEvent,
  ) => unknown | Promise<unknown>;
  onCopyDiscordClick?: (
    user: api.User,
    event: React.MouseEvent,
  ) => unknown | Promise<unknown>;
}

export const GameProfileCard: React.FC<GameProfileCardProps> = props => {
  switch (props.profile.gameId as SupportedGameId) {
    case api.GameId.LeagueOfLegends:
      return <LolProfileCard {...props} />;
    case api.GameId.WorldOfWarcraft:
      return <WowProfileCard {...props} />;
    case api.GameId.LostArkEn:
      return <LostArkProfileCard {...props} />;
    case api.GameId.PubgMobile:
      return <PubgMobileProfileCard {...props} />;
    case api.GameId.CodMobile:
      return <CodMobileProfileCard {...props} />;
    case api.GameId.Csgo:
      return <CsgoProfileCard {...props} />;
    case api.GameId.Standoff2:
      return <Standoff2ProfileCard {...props} />;
    default:
      return <AnyProfileCard {...props} />;
  }
};
