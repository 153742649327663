import React, { useState, useMemo } from 'react';

import cx from 'classnames';

import {
  MessageText,
  SHOW_LESS_TEXT,
  SHOW_MORE_TEXT,
} from 'components/Message/MessageText';

import { Text } from '../../Text';

import css from './CardDescription.module.css';

export interface CardDescriptionProps {
  description: string | undefined;
  messageClassName?: string | undefined;
  limitHeight?: boolean;
  className?: string;
  maxCharacters?: number;

  renderShowMore?: (toggleMore: () => void, isOpened: boolean) => React.ReactNode;

  TextFormatter?: typeof Text.R3Memo;
}

export const CardDescription = React.memo<CardDescriptionProps>(
  ({
    description,
    limitHeight,
    className,
    messageClassName,
    maxCharacters,
    TextFormatter = Text.R3Memo,
  }) => {
    const [isExtended, setIsExtended] = useState(false);

    const shouldTrim =
      maxCharacters && description && description.length > maxCharacters;

    const shownText = useMemo(() => {
      if (!description) {
        return '';
      }

      if (isExtended) {
        if (shouldTrim) {
          return description + ' \n' + SHOW_LESS_TEXT;
        }

        return description;
      }

      if (shouldTrim) {
        return description.slice(0, maxCharacters) + ' ...\n' + SHOW_MORE_TEXT;
      }

      return description;
    }, [isExtended, shouldTrim, description, maxCharacters]);

    if (!description) {
      return null;
    }

    return (
      <TextFormatter className={cx(css.container, className)}>
        <MessageText
          className={cx(
            css.text,
            limitHeight ? css.clampedText : '',
            messageClassName,
          )}
          text={shownText}
          onClickAction={(_, action) => {
            if (action.type === 'SHOW_MORE') {
              setIsExtended(!isExtended);
            }
          }}
        />
      </TextFormatter>
    );
  },
);
