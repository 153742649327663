import React from 'react';

import Link from 'next/link';

import { Text } from 'components/Text';

import css from './PopupTitle.module.css';

import type { UrlObject } from 'url';

export interface PopupTitleProps {
  title: string;
  link: UrlObject;
  onClick?: (event: React.MouseEvent) => Promise<void> | void;
}

export const PopupTitle = React.memo<PopupTitleProps>(({ title, onClick, link }) => {
  return (
    <Link href={link} onClick={onClick}>
      <Text.H2 bold className={css.containerText}>
        {title}
      </Text.H2>
    </Link>
  );
});
