import React, { useMemo } from 'react';

import { api } from 'api';
import { Separator } from 'components/Card/CardParamsTable';
import { Text } from 'components/Text';
import { useText } from 'text';

import css from './LolWinrateParam.module.css';

interface LolWinrateProps {
  game: api.GameLol;
  short?: boolean;
}

export const LolWinrateParam = React.memo<LolWinrateProps>(({ game }) => {
  const text = useText(state => state.gameData.lol);

  const [wins, loses] = useMemo(() => {
    if ((game.soloWinCount || 0) > 0 && (game.soloLoseCount || 0) > 0) {
      return [game.soloWinCount || 0, game.soloLoseCount || 0];
    }

    return [game.normalWinCount || 0, game.normalLoseCount || 0];
  }, [game]);

  return (
    <div className={css.container}>
      <Text.R4 className={css.winText}>{text.winRate.wins.full(wins)}</Text.R4>
      <Separator />
      <Text.R4 className={css.loseText}>{text.winRate.loses.full(loses)}</Text.R4>
      <Separator />
      <Text.R4 className={css.text}>
        {parseInt(((100 * wins) / (loses + wins || 1)) as any)}%
      </Text.R4>
    </div>
  );
});
