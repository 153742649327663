import React from 'react';

import { api } from 'api';
import { Text } from 'components/Text';
import { useTranslations } from 'text';

import { EventTag } from './EventTag';

import css from './EventTag.module.css';

interface EventTagListProps {
  event: api.Event;
  TextFormatter?: typeof Text.R3Memo;
}

export const EventTagList: React.FC<EventTagListProps> = ({
  event,
  TextFormatter = Text.R3Memo,
}) => {
  const text = useTranslations();
  const list: string[] = [];

  list.push(text.gameTitle(event.gameId).short);

  const typeText = text.eventType(event.type);
  if (event.type && typeText) {
    list.push(typeText);
  }

  return (
    <TextFormatter className={css.list}>
      {list.map((item, key) => (
        <EventTag key={key}>{item}</EventTag>
      ))}
    </TextFormatter>
  );
};
