import React from 'react';

import css from './EventPopupWrapper.module.css';

export interface EventPopupWrapperProps {
  topContent: React.ReactNode;
  children: React.ReactNode;
}

export const EventPopupWrapper = React.memo<EventPopupWrapperProps>(
  ({ children, topContent }) => {
    return (
      <template className={css.container}>
        <header className={css.topContainer}>{topContent}</header>
        <section className={css.wrapper}>{children}</section>
      </template>
    );
  },
);
