import React, { useMemo } from 'react';

import cx from 'classnames';

import { api } from '../../../api';
import { utils } from '../../../utils';
import { mapClassToStyle, mapRioScoreToColor } from '../../GameCard/wow';
import { Icon } from '../../Icons';
import { useText } from '../../Language';
import { useHighlightedGames } from '../../PagePlayers/helpers';
import { Text } from '../../Text';
import { useToasts } from '../../Toast';

import { LinkUser } from '../../Utils/Utils';

import css from './ProfileInfoGames.module.css';
const AMOUNT_OF_DISPLAYED_CHARACTERS = 3;

export interface LfgCardProps {
  lfg: api.Lfg;
  isMe?: boolean;
  username?: string;
  showAllChars?: boolean;
  isMobileProfile?: boolean;
}

export const ProfileInfoWow: React.FC<LfgCardProps> = ({
  lfg,
  username,
  showAllChars,
  isMobileProfile,
}) => {
  const text = useText(state => state.userSearch.gameCards);

  const titles = [text.characters, 'ilvl', 'Mythic+', text.wow.raid, '2x2', '3x3'];

  const showedGames = useMemo(() => {
    const games = lfg.games?.filter((game: any) => !game.hidden) as api.Wow[];
    const sorted = games.sort((a, b) => ((a?.ilvl || 0) > (b?.ilvl || 0) ? -1 : 1));
    if (!showAllChars) {
      return sorted.slice(0, AMOUNT_OF_DISPLAYED_CHARACTERS);
    }

    return sorted;
  }, [lfg.games]);

  const More = useMemo((): React.ReactNode => {
    if (showAllChars || !username) {
      return null;
    }

    const moreCount = (lfg.games?.length || 0) - AMOUNT_OF_DISPLAYED_CHARACTERS;
    if (moreCount <= 0) {
      return null;
    }

    return (
      <LinkUser username={username}>
        <div className={css.moreContainer}>
          <Text.R3 semibold className={css.moreText}>
            {text.redirectToMore(moreCount)}
          </Text.R3>
        </div>
      </LinkUser>
    );
  }, [showAllChars, lfg.games]);

  return (
    <div className={css.wowTableWrapper}>
      <table className={css.wowTable}>
        <tbody>
          <tr className={css.tableHeaders}>
            {titles.map((title, key) => (
              <th key={key}>
                <Text.R4 className={css.tableLabel}>{title}</Text.R4>
              </th>
            ))}
          </tr>
          {showedGames.map((game, key) => (
            <WowGameCard key={key} game={game} lfg={lfg} username={username} />
          ))}
        </tbody>
      </table>

      {More}
      <MobileProfileCardShadow isMobileProfile={isMobileProfile} />
    </div>
  );
};

export const MobileProfileCardShadow: React.FC<{ isMobileProfile?: boolean }> = ({
  isMobileProfile,
}) => (
  <div
    className={cx(css.mobileShadow, { [css.mobileProfileShadow]: isMobileProfile })}
  />
);

interface WowGameCardProps {
  game: api.Wow;
  lfg: api.Lfg;

  dimmed?: boolean;
  username?: string;
}

const WowGameCard: React.FC<WowGameCardProps> = ({ username, game, lfg, dimmed }) => {
  const textCopied = useText(state => state.userGames.card);
  const { alert } = useToasts();

  const [] = useHighlightedGames(game.id);
  const aliasLabel = game.alias && game.alias.length > 29 ? game.alias : undefined;
  const rioScorePrevious = game.rioScorePrevious ? `(${game.rioScorePrevious})` : '';

  return (
    <tr style={dimmed ? { opacity: 0.5 } : {}}>
      <td>
        <div className={css.firstColumnData}>
          {game.thumbnailUrl ? (
            <div className={css.wowImageContainer}>
              <img className={css.wowImage} src={game.thumbnailUrl} />
            </div>
          ) : null}
          <a
            className={css.aliasDataWrapper}
            href={utils.getArmoryLinkByLfg(game, lfg)}
            rel="noopener nofollow noreferrer"
            target="_blank"
          >
            <label className={css.aliasLabel} title={aliasLabel}>
              <Text.R3
                semibold
                className={cx(css.tableData, css.aliasData)}
                style={game.wowClass ? mapClassToStyle(game.wowClass) : {}}
              >
                {game.alias}
              </Text.R3>
            </label>
          </a>
        </div>
      </td>
      <TextTableData>{game.ilvl || 0}</TextTableData>
      <TextTableData style={{ color: mapRioScoreToColor(game.rioScore || 0) }}>
        {`${game.rioScore || 0} ${rioScorePrevious}`}
      </TextTableData>
      <TextTableData>{game.raidSepulcherSummary || '–'}</TextTableData>
      <TextTableData>{game.arenaRating2x2 || '–'}</TextTableData>
      <TextTableData>{game.arenaRating3x3 || '–'}</TextTableData>
      <td>
        <article
          className={css.copyIconWrapper}
          onClick={() => {
            utils.copyGameLink(username, game.id);

            alert({
              message: textCopied.toastInviteCopied,
            });
          }}
        >
          <Icon.Copy className={css.copyIcon} />
        </article>
      </td>
    </tr>
  );
};

interface TextTableDataProps {
  children: string | number;
  style?: React.CSSProperties;
  className?: string;
}

const TextTableData = React.memo<TextTableDataProps>(
  ({ children, style, className }) => {
    return (
      <td>
        <Text.R3 semibold className={cx(css.tableData, className)} style={style}>
          {children}
        </Text.R3>
      </td>
    );
  },
);
