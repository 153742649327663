import React, { useMemo } from 'react';

import { CardParam, CardParams, CardParamsTable } from 'components/Card';

import { useLabelAliasText } from 'components/GameForms/Alias';

import { GameProfileCardTemplate } from '../Template';

import type { GameProfileCardProps } from '../[Game]ProfileCard';

export const checkForLength = (
  value: string,
  paramsPosition: 'top' | 'bottom',
  isMobileProfile: boolean,
  callback: () => void,
) => {
  if (!value) {
    return;
  }

  if (!isMobileProfile) {
    callback();

    return;
  }

  if (paramsPosition === 'top') {
    if (value.length <= 13) {
      callback();

      return;
    }
  } else {
    if (value.length > 13) {
      callback();

      return;
    }
  }
};

export const AnyProfileCard: React.FC<GameProfileCardProps> = props => {
  const profile = props.profile;

  const aliasText = useLabelAliasText(profile.gameId);

  const isEmptyProfile = !profile.alias && !profile.region;
  const isMobileProfile = Boolean(props.isMobile && props.hideUser);

  const cardParams = useMemo<CardParam[]>(() => {
    const params: CardParam[] = [];

    if (profile.alias) {
      checkForLength(profile.alias, 'top', isMobileProfile, () => {
        params.push({
          title: aliasText,
          value: profile.alias,
        });
      });
    }
    if (profile.region) {
      params.push({
        title: 'Region',
        value: profile.region,
      });
    }

    return params;
  }, [profile, aliasText]);

  const bottomParams = useMemo<CardParam[]>(() => {
    const params: CardParam[] = [];

    if (profile.alias) {
      checkForLength(profile.alias, 'bottom', isMobileProfile, () => {
        params.push({
          title: aliasText,
          value: profile.alias,
        });
      });
    }

    if (profile.accountId) {
      checkForLength(profile.accountId, 'bottom', isMobileProfile, () => {
        params.push({
          title: 'Game ID',
          value: profile.accountId,
        });
      });
    }

    return params;
  }, [profile, aliasText]);

  const topContent = (): React.ReactNode => {
    if (!cardParams.length) {
      return null;
    }

    if (isMobileProfile) {
      return <CardParams values={cardParams} />;
    }

    return <CardParamsTable values={cardParams} />;
  };
  const bottomContent = (): React.ReactNode => {
    if (!bottomParams.length) {
      return null;
    }

    if (isMobileProfile) {
      return <CardParams values={bottomParams} />;
    }

    return <CardParamsTable values={bottomParams} />;
  };

  return (
    <GameProfileCardTemplate
      bottomContent={bottomContent()}
      isEmptyCard={isEmptyProfile}
      topContent={topContent()}
      {...props}
    />
  );
};
