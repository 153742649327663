import { api } from 'api';

export const rioClassColors: Record<api.WowClass, string> = {
  [api.WowClass.Druid]: '#ff7d0a',
  [api.WowClass.DeathKnight]: '#c41f3b',
  [api.WowClass.DemonHunter]: '#a330c9',
  [api.WowClass.Hunter]: '#abd473',
  [api.WowClass.Mage]: '#69ccf0',
  [api.WowClass.Monk]: '#00ff96',
  [api.WowClass.Paladin]: '#f58cba',
  [api.WowClass.Priest]: '#ffffff',
  [api.WowClass.Rogue]: '#fff569',
  [api.WowClass.Shaman]: '#0070de',
  [api.WowClass.Warlock]: '#9482c9',
  [api.WowClass.Warrior]: '#c79c6e',
};
const rioFactionColors: Record<api.WowFaction, string> = {
  [api.WowFaction.Alliance]: '#69f',
  [api.WowFaction.Horde]: '#e02929',
};

export function mapClassToStyle(wowClass: api.WowClass): React.CSSProperties {
  return { color: rioClassColors[wowClass] };
}
export function mapFactionToStyle(
  wowFaction: api.Maybe<api.WowFaction>,
): React.CSSProperties {
  if (!wowFaction) {
    return {};
  }

  return { color: rioFactionColors[wowFaction] };
}

export function mapFactionToBackground(
  wowFaction: api.Maybe<api.WowFaction>,
): React.CSSProperties {
  if (!wowFaction) {
    return {};
  }

  if (wowFaction === api.WowFaction.Alliance) {
    return { backgroundColor: 'var(--wow-alliance-bg)' };
  }

  return { backgroundColor: 'var(--wow-horde-bg)' };
}

export const mapRioScoreToColor = (rioScore: number): string => {
  if (rioScore < 480) {
    return 'var(--white)';
  }

  if (rioScore < 960) {
    return '#1eff00';
  }

  if (rioScore < 1600) {
    return '#0070dd';
  }

  if (rioScore < 2400) {
    return '#a335ee';
  }

  return '#ff8000';
};

export const mapArenaScoreToColor = (arenaScore: number): string => {
  if (arenaScore < 1400) {
    return 'var(--white)';
  }
  if (arenaScore < 1600) {
    return '#1eff00';
  }
  if (arenaScore < 1800) {
    return '#0070dd';
  }
  if (arenaScore < 2100) {
    return '#a335ee';
  }

  return '#ff8000';
};
