import React, { useMemo } from 'react';

import cx from 'classnames';

import { LfgTag } from 'api';

import { Microphone } from 'components/Icons/Icons';
import { Text } from 'components/Text';

import { useTranslations } from 'text';

import css from './CardTags.module.css';

export interface CardTagsProps {
  tags: string[];
  selectedTags?: string[];

  onTagClick?: (tag: LfgTag, event: React.MouseEvent) => void;
}

export const CardTags = React.memo<CardTagsProps>(
  ({ tags, selectedTags, onTagClick = () => undefined }) => {
    const text = useTranslations();

    const [filteredTags, withVoice] = useMemo(() => {
      const filteredTags = tags.filter(tag => tag !== LfgTag.Voice);
      const withVoice = tags.includes(LfgTag.Voice);

      return [filteredTags, withVoice];
    }, [tags]);

    if (tags.length === 0) {
      return null;
    }

    return (
      <div className={css.container}>
        {withVoice ? (
          <div className={css.tagWrapper}>
            <Microphone height={14} width={14} />
          </div>
        ) : null}
        {filteredTags.map(tag => (
          <Text.R4 key={tag} className={css.tagWrapper}>
            #{text.lfg.tag(tag as LfgTag)}
          </Text.R4>
        ))}
      </div>
    );
  },
);
