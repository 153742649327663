import React from 'react';

import cx from 'classnames';
import Highlighter from 'react-highlight-words';

import { useText } from 'text';

export const SHOW_MORE_TEXT = " ''SHOW_MORE";
export const SHOW_LESS_TEXT = " ''SHOW_LESS";

import { OnClickAction } from './Message';
import css from './Message.module.css';

import { User } from './types';
import {
  boldRegex,
  codeBlockRegex,
  hashtagRegex,
  inlineCodeRegex,
  isOnlyEmojis,
  italicsRegex,
  mentionRegex,
  underlineRegex,
  urlRegex,
  emailRegex,
} from './utils';

export interface MessageTextProps {
  text: string;
  user?: User;
  onClickAction?: OnClickAction;
  style?: React.CSSProperties;
  bigEmoji?: boolean;
  className?: string;
  blurred?: boolean;
  clamped?: boolean;
}

export const MessageText: React.FC<MessageTextProps> = ({
  text,
  user,
  className,
  style = {},
  bigEmoji = false,
  blurred = false,
  clamped = false,
  onClickAction = () => null,
}) => {
  if (text.length === 0) {
    return null;
  }

  const Highlight = ({
    children: targetText,
  }: {
    children: string;
    highlightIndex: number;
  }) => {
    const text = useText(state => state.controls.actions);

    if (targetText === SHOW_MORE_TEXT) {
      return (
        <>
          <button
            className={css.moreButton}
            onClick={event => onClickAction(event, { type: 'SHOW_MORE' })}
          >
            {text.showMore}
          </button>
        </>
      );
    }

    if (targetText === SHOW_LESS_TEXT) {
      return (
        <button
          className={css.moreButton}
          onClick={event => onClickAction(event, { type: 'SHOW_MORE' })}
        >
          {text.showLess}
        </button>
      );
    }

    // URL
    if (Boolean(targetText.match(urlRegex))) {
      const formatted = targetText.replace(/https?:\/\//, '');
      const link = targetText;

      return (
        <Link
          openNewPage
          className={cx(css.url, { [css.blurredText]: blurred })}
          href={link}
        >
          {formatted.length > 500 ? `${formatted.slice(0, 500)}...` : formatted}
        </Link>
      );
    }

    // Email
    if (Boolean(targetText.match(emailRegex))) {
      return (
        <a className={cx(css.email)} href={`mailto:${targetText}`}>
          {targetText}
        </a>
      );
    }

    // Mention
    if (Boolean(targetText.match(mentionRegex))) {
      return (
        <span
          className={cx(css.mention, {
            [css.mentionMe]: user && targetText.includes(user.name),
          })}
          onClick={event =>
            onClickAction(event, {
              screenName: targetText.replace('@', ''),
              type: 'MENTIONED_USER',
            })
          }
        >
          {targetText}
        </span>
      );
    }

    // Bold
    if (Boolean(targetText.match(boldRegex))) {
      return <strong>{targetText.replace(/\*\*/g, '')}</strong>;
    }

    // Underline
    if (Boolean(targetText.match(underlineRegex))) {
      return <u>{targetText.replace(/__/g, '')}</u>;
    }

    // Italics
    if (Boolean(targetText.match(italicsRegex))) {
      return <em>{targetText.replace(/\*|_/g, '')}</em>;
    }

    // // Code block
    // if (Boolean(targetText.match(codeBlockRegex))) {
    //   return (
    //     <SyntaxHighlighter
    //       customStyle={{ margin: '8px 0px 4px', borderRadius: 8, fontSize: 14 }}
    //     >
    //       {targetText.replace(/```/g, '').trim()}
    //     </SyntaxHighlighter>
    //   );
    // }

    // Inline code
    if (Boolean(targetText.match(inlineCodeRegex))) {
      return <code className={css.inlineCode}>{targetText.replace(/`/g, '')}</code>;
    }

    // Hashtag
    if (Boolean(targetText.match(hashtagRegex))) {
      return (
        <span
          className={css.hashtag}
          onClick={event => onClickAction(event, { type: 'HASHTAG' })}
        >
          {targetText}
        </span>
      );
    }

    return <>{targetText}</>;
  };

  const renderText = () => {
    if (text.length < 12 && isOnlyEmojis(text) && bigEmoji) {
      let size = 18;
      let lineHeight = '22px';
      let letterSpacing = 0;

      if (text.length === 2) {
        size = 50;
        lineHeight = '57px';
      } else if (text.length === 4) {
        size = 40;
        lineHeight = '42px';
      } else if (text.length === 6) {
        size = 30;
        lineHeight = '39px';
      } else if (text.length <= 18) {
        size = 25;
        lineHeight = '27px';
      } else {
        letterSpacing = 0.22;
      }

      return <div style={{ fontSize: size, lineHeight, letterSpacing }}>{text}</div>;
    }

    return (
      <Highlighter
        className={className}
        highlightTag={Highlight}
        searchWords={[
          codeBlockRegex,
          emailRegex,
          mentionRegex as any,
          hashtagRegex as any,
          urlRegex as any,
          boldRegex as any,
          underlineRegex,
          italicsRegex,
          inlineCodeRegex,
          SHOW_MORE_TEXT,
          SHOW_LESS_TEXT,
        ]}
        textToHighlight={text}
      />
    );
  };

  return (
    <div style={{ display: 'inline-block' }}>
      <div
        className={cx(css.newText, { [css.blurredText]: blurred })}
        style={style}
        onClick={event => onClickAction(event, { type: 'TEXT' })}
      >
        {renderText()}
      </div>
    </div>
  );
};

export interface LinkProps {
  href: string;
  children: any;
  className?: string;
  openNewPage?: boolean;
  onClick?: (event: React.MouseEvent) => void;
}
export const Link: React.FC<LinkProps> = ({
  href,
  children,
  className,
  openNewPage = false,
  onClick = () => null,
}) => {
  return (
    <a
      className={cx(css.linkContainer, {
        ...(className && { [className]: true }),
      })}
      href={href}
      {...(openNewPage && {
        rel: 'noopener',
        target: '_blank',
      })}
      onClick={onClick}
    >
      {children}
    </a>
  );
};
