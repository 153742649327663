import { api } from 'api';

export type EventAction =
  | { type: 'JOIN'; slotType: api.SlotType }
  | { type: 'LEAVE' }
  | { type: 'SHARE' }
  | { type: 'CHAT' }
  | { type: 'OPEN_EVENT' }
  | { type: 'FOLLOW_HOST' }
  | { type: 'PLAY' }
  | { type: 'LIKE' };

export function formatInt(num: number | undefined): string {
  if (!num) {
    return '0';
  }

  if (num < 1000) {
    return num.toString();
  }

  const thousands = (num / 1000).toFixed(1);
  const [whole, fractional] = thousands.split('.');

  if (fractional === '0') {
    return `${whole}K`;
  }

  return `${whole}.${fractional}K`;
}

export function countMembers(event: api.Event): string | undefined {
  // Links do not have members
  if (event.type === api.EventType.Link) {
    return undefined;
  }

  if (event.type === api.EventType.Giveaway) {
    const count = event.slotsCountByType.find(
      slotType => slotType.type === api.SlotType.Waitlist,
    );

    return formatInt(count?.taken);
  }

  return formatInt(event.takenSlotsCount);
}
