import React, { useMemo } from 'react';

import { api } from 'api';

import { CardParam, CardParams, CardParamsTable } from 'components/Card';

import { useLabelAliasText } from 'components/GameForms/Alias';
import { useTranslations, useText } from 'text';

import { checkForLength } from '../Any/AnyProfileCard';
import { GameProfileCardTemplate } from '../Template';

import type { GameProfileCardProps } from '../[Game]ProfileCard';

export const CsgoProfileCard: React.FC<GameProfileCardProps> = ({ ...props }) => {
  const text = useText(state => state.gameData.csgo);

  const translations = useTranslations();

  const profile = props.profile;
  const aliasText = useLabelAliasText(api.GameId.Csgo);

  const isMobileProfile = Boolean(props.isMobile && props.hideUser);

  const params = useMemo<CardParam[]>(() => {
    const params: CardParam[] = [];

    if (profile.alias) {
      checkForLength(profile.alias, 'top', isMobileProfile, () => {
        params.push({
          title: aliasText,
          value: profile.alias,
        });
      });
    }

    if (profile.region) {
      params.push({
        title: text.region.title,
        value: profile.region,
      });
    }

    return params;
  }, [profile?.alias, profile?.region]);

  const bottomParams = useMemo<CardParam[]>(() => {
    const params: CardParam[] = [];
    if (profile.alias) {
      checkForLength(profile.alias, 'bottom', isMobileProfile, () => {
        params.push({
          title: aliasText,
          value: profile.alias,
        });
      });
    }
    if (profile?.rank) {
      params.push({
        title: text.rank.title,
        value: translations.csgo.rank(profile?.rank as api.CsgoRank),
      });
    }
    if (profile?.csgo?.faceitLvl) {
      params.push({
        title: 'FACEIT Lvl',
        value: profile?.csgo?.faceitLvl,
      });
    }

    return params;
  }, [profile?.rank]);

  const topContent = (): React.ReactNode => {
    if (!params.length) {
      return null;
    }

    if (isMobileProfile) {
      return <CardParams values={params} />;
    }

    return <CardParamsTable values={[...params, ...bottomParams]} />;
  };

  const bottomContent = (): React.ReactNode => {
    if (!isMobileProfile) {
      return null;
    }
    if (!bottomParams.length) {
      return null;
    }

    return <CardParams values={bottomParams} />;
  };

  const isEmptyProfile = [...params, ...bottomParams].length === 0;

  return (
    <GameProfileCardTemplate
      {...props}
      bottomContent={bottomContent()}
      isEmptyCard={isEmptyProfile}
      topContent={topContent()}
    />
  );
};
