import React, { useMemo } from 'react';

import { api } from 'api';

import { CardParam, CardParams, CardParamsTable } from 'components/Card';
import { LostArkCharactersTable } from 'components/ProfileCardList/ProfileInfoGames/ProfileInfoLostArk';
import { useTranslations, useText } from 'text';
import { filterGames } from 'utils/main';

import { GameProfileCardTemplate } from '../Template';

import type { GameProfileCardProps } from '../[Game]ProfileCard';

export const LostArkProfileCard: React.FC<GameProfileCardProps> = ({ ...props }) => {
  const text = useText(state => state.gameData.lostArk);

  const translations = useTranslations();

  const profile = props.profile;
  const games = filterGames<api.LostArk>(api.GameId.LostArkEn, profile?.games || []);

  const isMobileProfile = props.isMobile && props.hideUser;

  const params = useMemo<CardParam[]>(() => {
    const params: CardParam[] = [];

    if (profile.lostArk?.region?.length) {
      const region = profile.lostArk.region[0] as api.LostArkRegion;

      params.push({
        title: text.region.title,
        value: translations.lostArk.region(region),
      });
    }

    if (profile.lostArk?.server?.length) {
      const server = profile.lostArk.server[0];

      params.push({
        title: text.server.title,
        value: server,
      });
    }

    if (profile.lostArk?.legacyLvl) {
      params.push({
        title: 'ilvl',
        value: profile.lostArk.legacyLvl,
      });
    }

    return params;
  }, [
    profile?.lostArk?.legacyLvl,
    profile?.lostArk?.server,
    profile?.lostArk?.region,
  ]);

  const topContent = (): React.ReactNode => {
    if (!params.length) {
      return null;
    }

    if (isMobileProfile) {
      return <CardParams values={params} />;
    }

    return <CardParamsTable values={params} />;
  };

  const bottomContent = (): React.ReactNode => {
    return <LostArkCharactersTable games={games} isMobileProfile={isMobileProfile} />;
  };

  const isEmptyProfile = [...params].length === 0 && (!games || games.length === 0);

  return (
    <GameProfileCardTemplate
      {...props}
      bottomContent={bottomContent()}
      isEmptyCard={isEmptyProfile}
      topContent={topContent()}
    />
  );
};
