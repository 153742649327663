import React from 'react';

import { api } from 'api';
import { LolRankIcon } from 'components/Icons__deprecated';
import { Text } from 'components/Text';

import { useTranslations } from 'text';

import css from './LolRankParam.module.css';

interface LolRankParamProps {
  rank: api.LolRank;
}

export const LolRankParam = React.memo<LolRankParamProps>(({ rank }) => {
  const translation = useTranslations();

  return (
    <div className={css.container}>
      <LolRankIcon className={css.icon} rank={rank} size={40} />
      <Text.R4 className={css.text}>{translation.wildRift.rank(rank)}</Text.R4>
    </div>
  );
});
