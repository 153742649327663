import React, { useCallback } from 'react';

import { ApolloError } from '@apollo/client';
import { useRouter } from 'next/router';

import { analytics } from 'analytics';
import { EventCategory } from 'analytics/groupFeed';
import { Iterator, api, optimistic, refetchMyCounters, useGetMeState } from 'api';
import { useApp } from 'components/ContextApp';
import { useAppLinksModal } from 'components/ContextModal/appLinks';
import { useOnboardingModal } from 'components/ContextModal/onboarding';
import { Error } from 'components/Error';
import { Layout } from 'components/Layout';
import { Pagination } from 'components/Pagination';
import { useToasts } from 'components/Toast';
import { useText } from 'text';
import { links } from 'utils/links';
import { copyToClipboard } from 'utils/main';
// import {ModalLaunchingGame} from 'containers/ModalLaunchingGame';

import { PostEvent } from './Container';
import { EventAction } from './helpers';
import css from './PostEventList.module.css';
// import { useModal } from 'components/ContextModal/helpers';

interface PostEventListProps {
  error?: ApolloError;
  isLoading?: boolean;
  events: Iterator<api.Event[]>;

  renderEmpty: () => React.ReactNode;
  onRequestMore: () => Promise<unknown> | unknown;
  _analyticGroup: EventCategory;
}

export const PostEventList: React.FC<PostEventListProps> = ({
  _analyticGroup,
  events,
  isLoading = false,
  error,
  renderEmpty,
  onRequestMore,
}) => {
  const { me } = useGetMeState();

  const onboardingModal = useOnboardingModal();
  const router = useRouter();
  const toasts = useToasts();
  const app = useApp();

  const appLinksModal = useAppLinksModal();

  const textCopied = useText(state => state.userGames.card.toastLinkCopied);
  const appLinksModalText = useText(state => state.modalAppLinks);
  const eventText = useText(state => state.event);

  const [followUser] = api.useFollowUserMutation({
    onError: error => toasts.showError(error, api.FollowUserDocument),
    onCompleted: () => {
      toasts.alert({ message: eventText.card.notification });
    },
    refetchQueries: [refetchMyCounters],
  });
  const [unfollowUser] = api.useUnfollowUserMutation({
    onError: error => toasts.showError(error, api.UnfollowUserDocument),
    refetchQueries: [refetchMyCounters],
  });
  const [likeEvent] = api.useLikeEventMutation({
    onError: error => {
      console.log(error);

      return toasts.error('Something failed');
    },
  });

  const [joinEvent] = api.useJoinEventMutation({
    onError: error => {
      const gqlError = error.graphQLErrors[0];
      const errorCode = gqlError.extensions?.code as api.Maybe<api.ErrorCode>;
      if (errorCode === api.ErrorCode.AppRequired) {
        appLinksModal.open({ title: appLinksModalText.titleOnJoin });

        return;
      }

      toasts.alert({ type: 'error', message: error.message });
    },
    onCompleted: result => {
      const event = result.joinEvent;
      const link = links.event(event.owner, event.id);

      router.push(link);
    },
  });
  const [leaveEvent] = api.useLeaveEventMutation({
    onError: error => {
      toasts.showError(error, api.LeaveEventDocument);
    },
  });

  const createEventActionHandler = useCallback(
    (event: api.Event) =>
      async (action: EventAction): Promise<void> => {
        const eventLink = links.event(event.owner, event.id);
        const isFollowing = event.owner?.isFollowing;

        switch (action.type) {
          case 'LIKE':
            analytics.clickLikeEvent({ eventId: event.id, eventName: event.name });

            if (!me) {
              return onboardingModal.open({});
            }

            likeEvent({
              variables: { eventId: event.id },
              optimisticResponse: optimistic.likeEvent(event),
            });

            return;
          case 'PLAY':
            analytics.clickPlay(event.id);
            console.error('Clicked play in card');

            // return modal.open(ModalLaunchingGame, {event});
            return;
          case 'FOLLOW_HOST':
            if (!me) {
              return onboardingModal.open({ title: undefined });
            }

            if (isFollowing) {
              analytics.clickUnfollow({
                category: _analyticGroup,
                additionalParams: {
                  profileId: event.owner.id,
                  profileUsername: event.owner.username,
                  isStreamer: String(
                    event.owner?.roles?.includes(api.UserRole.Streamer),
                  ),
                },
              });

              await unfollowUser({
                variables: { userId: event.owner.id },
                optimisticResponse: optimistic.unfollowUser(event.owner),
              });
            } else {
              analytics.clickFollow({
                category: _analyticGroup,
                additionalParams: {
                  profileId: event.owner.id,
                  profileUsername: event.owner.username,
                  isStreamer: String(
                    event.owner?.roles?.includes(api.UserRole.Streamer),
                  ),
                },
              });

              await followUser({
                variables: { userId: event.owner.id },
                optimisticResponse: optimistic.followUser(event.owner),
              });
            }

            return;
          case 'OPEN_EVENT':
            analytics.clickEventButton(_analyticGroup as any, event, 'view');

            router.push(eventLink);

            return;
          case 'JOIN':
            analytics.clickEventButton(_analyticGroup as any, event, 'join');

            if (!me) {
              return onboardingModal.open({
                onSuccessAfterClose: async () => {
                  joinEvent({
                    variables: {
                      data: { eventId: event.id, slotType: action.slotType },
                    },
                  });
                },
              });
            }

            await joinEvent({
              variables: { data: { eventId: event.id, slotType: action.slotType } },
            });

            return;
          case 'LEAVE':
            analytics.clickEventButton(_analyticGroup as any, event, 'leave');

            await leaveEvent({ variables: { eventId: event.id } });

            return;
          case 'CHAT':
            const chatLink = links.eventChat(event.owner, event.id);

            router.push(chatLink);

            return;
          case 'SHARE':
            analytics.clickGroupShare(_analyticGroup, event.id, event.gameId);

            if (navigator.share) {
              navigator.share({
                title: event.name,
                text: event.description || event.name,
                url: location.origin + (eventLink.pathname || ''),
              });
            } else {
              copyToClipboard(location.origin + (eventLink.pathname || ''));
              toasts.alert({ message: textCopied });
            }

            return;
        }
      },
    [],
  );

  const renderEvent = (event: api.Event) => {
    const handleRequestAction = createEventActionHandler(event);

    return (
      <PostEvent
        key={event.id}
        event={event}
        isEventOwner={me?.id === event.owner.id}
        isMobile={app.isMobile || false}
        onRequestAction={handleRequestAction}
      />
    );
  };

  if (events?.data) {
    if (events.data.length === 0) {
      return <>{renderEmpty()}</>;
    }

    return (
      <Pagination
        key="EVENT_POSTS"
        count={events?.count || 0}
        hasMore={events?.after || false}
        limit={events?.limit || 10}
        onRequestMore={onRequestMore}
      >
        <section className={css.container}>{events?.data.map(renderEvent)}</section>
      </Pagination>
    );
  }

  if (error) {
    return <Error subtext={JSON.stringify(error)}>Error!</Error>;
  }

  if (isLoading) {
    return <Layout.Loading />;
  }

  return <div />;
};
