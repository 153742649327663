import React from 'react';

import cx from 'classnames';

import css from './EventWrapperDivider.module.css';

interface EventWrapperDividerProps {
  className?: string;
}

export const EventWapperDivider = React.memo<EventWrapperDividerProps>(
  ({ className }) => {
    return <hr className={cx(css.divider, className)} />;
  },
);
