import React, { forwardRef, useMemo, useState } from 'react';

import cx from 'classnames';

import { getGridSizeStyle } from 'components/Form/FormGrid';
import { Text } from 'components/Text';

import css from './FormInput.module.css';

export type InputProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;

export interface FormInputProps extends InputProps {
  label: string;
  error?: string;
  inputWrapClassName?: string;
  gridSize?: number;
}

export const FormInput = forwardRef<HTMLInputElement, FormInputProps>(
  (
    { label, error, inputWrapClassName, className, id = label, gridSize, ...props },
    ref,
  ) => {
    const [isFocused, setIsFocused] = useState<boolean>(props.autoFocus || false);

    // "0" || 0 are valid values
    const isEmpty = props.value === undefined || props.value === '';

    const labelText = useMemo(() => {
      return label + (error ? ` (${error})` : '');
    }, [label, error]);

    return (
      <div
        className={cx(
          css.container,
          css.inputContainer,
          { [css.containerFocused]: isFocused },
          inputWrapClassName,
          className,
        )}
        style={getGridSizeStyle(gridSize)}
      >
        <Text.R2
          Tag="label"
          {...{ htmlFor: id }}
          className={cx(css.label, {
            [css.labelSmall]: isFocused || !isEmpty,
            'text-sm': isFocused || !isEmpty,
            [css.labelError]: Boolean(error),
            [css.labelRequired]: props.required,
          })}
        >
          {labelText}
        </Text.R2>
        <input
          ref={ref}
          autoCorrect="false"
          className={cx('text-lg', css.input, className)}
          {...props}
          autoComplete="off"
          id={id}
          value={props.value || ''}
          onBlur={event => {
            setIsFocused(false);
            if (props.onBlur) {
              props.onBlur(event);
            }
          }}
          onFocus={event => {
            setIsFocused(true);
            if (props.onFocus) {
              props.onFocus(event);
            }
          }}
        />
      </div>
    );
  },
);
