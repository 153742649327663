import React, { memo } from 'react';

import { api } from 'api';

import { IconProps } from './Icons';

export const UnsetIcon = memo<IconProps>(props => (
  <svg
    fill="none"
    height={20}
    stroke="var(--icon-color)"
    strokeLinecap="round"
    strokeWidth={1.5}
    viewBox="0 0 20 20"
    width={20}
    {...props}
  >
    <path d="M5.99999 3.07178L14 16.9282" />
    <path d="M18 10L2 10" />
    <path d="M14 3.07178L6.00001 16.9282" />
  </svg>
));
export const LolDuoIcon = memo<IconProps>(props => (
  <svg
    fill="var(--icon-color)"
    height={24}
    stroke="var(--icon-color)"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth="1.66667"
    viewBox="0 0 20 20"
    width={24}
    {...props}
  >
    <path d="M3.33325 13.75V3.33333H13.7499" fill="none" opacity="0.32" />
    <path d="M16.6667 6.25V16.6667H6.25" fill="none" />
    <rect height="3.33333" opacity="0.32" width="3.33333" x="8.33325" y="8.33334" />
  </svg>
));
export const LolJungleIcon = memo<IconProps>(props => (
  <svg fill="var(--icon-color)" height={24} viewBox="0 0 24 24" width={24} {...props}>
    <g stroke="none">
      <path d="M6.5 16.5L12 22C15 15.5 11 8 6.5 2.5C8.9 6.5 9.16667 11.6667 9 14C8 10.5 5 8.83333 3 8C6.5 11 6.5 15 6.5 16.5Z" />
      <path d="M13.8501 14.5C13.8501 12 12.3501 9.5 12.3501 9.5L17.3501 2.5C15.3501 6.5 14.1834 12.1667 13.8501 14.5Z" />
      <path d="M20.3634 7.5C14.8635 11.5 14.0303 16.8333 14.3636 19L17.3634 16C16.5634 12.4 18.8635 9 20.3634 7.5Z" />
    </g>
  </svg>
));

export const LolMidIcon = memo<IconProps>(props => (
  <svg
    fill="none"
    height={24}
    stroke="var(--icon-color)"
    viewBox="0 0 24 24"
    width={24}
    {...props}
  >
    <path
      d="M4 14.5V4H14.5"
      fill="none"
      opacity="0.32"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <path
      d="M20 9.5V20H9.5"
      fill="none"
      opacity="0.32"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <path
      d="M5 19L19 5"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
  </svg>
));
export const LolSoloIcon = memo<IconProps>(props => (
  <svg
    fill="var(--icon-color)"
    height={24}
    stroke="var(--icon-color)"
    viewBox="0 0 20 20"
    width={24}
    {...props}
  >
    <path
      d="M3.33325 13.75V3.33333H13.7499"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.66667"
    />
    <path
      d="M16.6667 6.25V16.6667H6.25"
      fill="none"
      opacity="0.32"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.66667"
    />
    <rect
      height="3.33333"
      opacity="0.32"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.66667"
      width="3.33333"
      x="8.33325"
      y="8.33334"
    />
  </svg>
));

export const WowRoleHeal = memo(props => (
  <svg fill="var(--icon-color)" height={20} viewBox="0 0 20 20" width={20} {...props}>
    <rect height="16" rx="0.888889" width="3.55556" x="8.2222" y="2" />
    <rect
      height={16}
      rx={0.89}
      transform="rotate(90 18 8.22223)"
      width={3.56}
      x={18}
      y={8.2}
    />
  </svg>
));
export const WowRoleDmg = memo(props => (
  <svg fill="var(--icon-color)" height={20} viewBox="0 0 20 20" width={20} {...props}>
    <path d="M17.8626 2.13742C17.7447 2.01951 17.5762 1.96898 17.4078 2.01951L14.6791 2.79432C14.5949 2.81117 14.5275 2.8617 14.4601 2.92907L6.6615 11.3172L5.14557 10.3403C5.01082 10.2561 4.82554 10.2729 4.70763 10.3908L3.91597 11.1825C3.78122 11.3173 3.78122 11.5362 3.91597 11.671L5.4656 13.2206L3.59594 15.1071C3.17485 15.0734 2.73691 15.2082 2.41688 15.5282C1.86104 16.084 1.86104 17.0104 2.41688 17.5831C2.98957 18.1558 3.91597 18.1558 4.48866 17.5831C4.80869 17.2631 4.94344 16.8251 4.90975 16.4041L6.77941 14.5344L8.32903 16.084C8.46378 16.2188 8.68275 16.2188 8.8175 16.084L9.626 15.2755C9.7439 15.1576 9.76075 14.9723 9.67653 14.8376L8.69959 13.3217L17.0709 5.52301C17.1383 5.47248 17.172 5.38826 17.2057 5.30404L17.9805 2.5922C18.031 2.42376 17.9805 2.25532 17.8626 2.13742Z" />
  </svg>
));

export const WowRoleTank = memo(props => (
  <svg fill="var(--icon-color)" height={20} viewBox="0 0 20 20" width={20} {...props}>
    <path d="M17.2 10.45V4.15C14.5653 3.68238 12.2296 2.44892 9.99999 1C7.77109 2.44845 5.43382 3.68253 2.79999 4.15V10.45C2.79999 13.8147 4.91677 16.457 7.74999 18.1C8.42628 18.4928 9.25114 18.7745 9.99999 19C10.4604 18.8186 11.2082 18.7649 12.25 18.1C14.99 16.3519 17.2 13.8187 17.2 10.45Z" />
  </svg>
));

export const LolSupportIcon = memo<IconProps>(props => (
  <svg fill="var(--icon-color)" height={20} viewBox="0 0 20 20" width={20} {...props}>
    <path
      clipRule="evenodd"
      d="M6.81955 3.9966L9.81275 6.61565L12.4318 3.9966L11.3094 2.5H7.942L6.81955 3.9966ZM6.4455 5.49316L8.31624 6.98976L7.1938 11.1054L4.2006 9.98296L5.6972 7.73806C4.69946 7.61335 2.32985 6.98976 0.833252 5.49316H6.4455ZM13.5543 5.49316L11.6836 6.98976L12.806 11.1054L15.7992 9.98296L14.3026 7.73806C15.3004 7.61335 17.67 6.98976 19.1666 5.49316H13.5543ZM7.56792 15.5952L9.06452 7.73804L9.81282 8.48634L10.5611 7.73804L12.0577 15.5952L9.81282 17.8401L7.56792 15.5952Z"
      fillRule="evenodd"
      stroke="none"
    />
  </svg>
));

interface LolRoleIcon extends IconProps {
  wildRiftRole: api.SlotRole;
}

export const LolRoleIcon = memo<LolRoleIcon>(({ wildRiftRole, ...props }) => {
  if (!wildRiftRole) {
    return null;
  }

  switch (wildRiftRole) {
    case api.SlotRole.LolDuo:
      return <LolDuoIcon {...props} />;
    case api.SlotRole.LolJungle:
      return <LolJungleIcon {...props} />;
    case api.SlotRole.LolMid:
      return <LolMidIcon {...props} />;
    case api.SlotRole.LolSolo:
      return <LolSoloIcon {...props} />;
    case api.SlotRole.LolSupport:
      return <LolSupportIcon {...props} />;
    default:
      return null;
  }
});
