import React from 'react';

import cx from 'classnames';

import { Icon } from 'components/Icons';
import { useToasts } from 'components/Toast';
import { useText } from 'text';
import { copyToClipboard } from 'utils/main';

import { Text } from '../../Text';

import css from './CardParamsTable.module.css';

export interface CardParam {
  title: React.ReactNode;
  value: React.ReactNode | React.ReactNode[];
  copyable?: boolean;
}
export interface CardParamsTableProps {
  values: CardParam[];

  leftPadding?: boolean;
  scrolled?: boolean;

  style?: React.CSSProperties;
}

export const CardParamsTable = React.memo<CardParamsTableProps>(
  ({ values, scrolled = true, leftPadding = false, style }) => {
    const textCopied = useText(state => state.userGames.card.toastLinkCopied);
    const { alert } = useToasts();

    const renderHeaders = (): React.ReactNode[] => {
      return values.map(({ title }, index) => (
        <th key={index}>
          <Text.R4Memo className={css.tableLabel}>{title}</Text.R4Memo>
        </th>
      ));
    };

    const renderMultiValues = (
      values: React.ReactNode[],
      index: number,
    ): React.ReactNode => {
      return (
        <td key={index}>
          <div className={css.tableMultiData}>
            {values.map((value, index) => (
              <React.Fragment key={index}>
                {index !== 0 && <Separator />}
                <Text.R4Memo className={css.tableData}>{value}</Text.R4Memo>
              </React.Fragment>
            ))}
          </div>
        </td>
      );
    };

    const renderValues = (): React.ReactNode[] => {
      return values.map(({ value, copyable }, index) => {
        if (Array.isArray(value)) {
          return renderMultiValues(value, index);
        }

        return (
          <td
            key={index}
            onClick={() => {
              if (copyable) {
                copyToClipboard(value as string);

                alert({ message: textCopied });
              }
            }}
          >
            <div
              className={css.tableDataWrapper}
              style={{ cursor: copyable ? 'pointer' : 'default' }}
            >
              <Text.R4Memo className={css.tableData}>{value}</Text.R4Memo>
              {copyable ? <Icon.Copy className={css.copyIcon} /> : null}
            </div>
          </td>
        );
      });
    };

    return (
      <div
        className={cx(css.wrapper, {
          [css.withPadding]: leftPadding,
          [css.scrolledWrapper]: scrolled,
        })}
      >
        <table className={css.container} style={style}>
          <tbody>
            <tr>{renderHeaders()}</tr>
            <tr>{renderValues()}</tr>
          </tbody>
        </table>
      </div>
    );
  },
);

export const CardParams = React.memo<CardParamsTableProps>(({ values }) => {
  return (
    <div className={css.cardParams}>
      {values.map(({ value, title }, index) => {
        return (
          <Text.R4Memo key={index} className={css.cardParam}>
            {`${title}: ${value}`}
          </Text.R4Memo>
        );
      })}
    </div>
  );
});

export const Separator = React.memo(() => <div className={css.dot} />);
