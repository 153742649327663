export const urlRegex = /https?\S+/gi; // new RegExp('(http|https)://((\\w)*|([0-9]*)|([-|_])*)+([\\.|/]((\\w)*|([0-9]*)|([-|_])*))+');
export const hashtagRegex = /#[a-z0-9_]{1,50}/gi;
export const mentionRegex = /@([\w_.-]+){1,50}/gi;
export const emailRegex = /[A-z0-9-_]{1,50}@[A-z0-9-_]{1,50}[.][A-z]{1,7}/gi;

export const boldRegex = /(\*\*)(.*?)\1/g;
export const italicsRegex = /(\*|_)(.*?)\1/g;

export const underlineRegex = /(__)(.*?)\1/g;

export const codeBlockRegex = /```[a-z]*\n[\s\S]*?\n```/g;
export const inlineCodeRegex = /(`)(.*?)\1/g;

export const emojiRanges = [
  '\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff]',
  ' ', // Also allow spaces
].join('|');

const removeEmoji = (str: string) => str.replace(new RegExp(emojiRanges, 'g'), '');

export const isOnlyEmojis = (str: string) => !removeEmoji(str).length;
