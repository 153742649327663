import React, { useMemo } from 'react';

import { ApolloError } from '@apollo/client';
import cx from 'classnames';

import { api, Iterator, LfgTag } from 'api';

import { Error } from 'components/Error';
import { Layout } from 'components/Layout';
import { Pagination } from 'components/Pagination';

import { GameProfileCard } from './[Game]ProfileCard';

import css from './[Game]ProfileCard.module.css';

interface ProfileCardListProps {
  users: Iterator<api.User[]>;

  renderEmpty: React.ReactElement;
  isLoading?: boolean;
  isMobile?: boolean;
  error: api.Maybe<ApolloError>;

  hideUser?: boolean;
  extended?: boolean;

  selectedTags?: string[];
  onRequestMore?: () => Promise<unknown> | unknown;
  onUserClick?: (
    user: api.User,
    event: React.MouseEvent,
  ) => unknown | Promise<unknown>;
  onTagClick?: (tag: LfgTag, event: React.MouseEvent) => void;

  renderActions?: (user: api.User, profile: api.Lfg) => React.ReactElement | null;
  onCopyDiscordClick?: (
    user: api.User,
    event: React.MouseEvent,
  ) => unknown | Promise<unknown>;
  onRequestEditLfg?: (gameId: api.GameId) => Promise<void> | void;
}

export const GameProfileCardList: React.FC<ProfileCardListProps> = ({
  users,
  renderEmpty,
  isLoading,
  error,
  selectedTags,
  hideUser,
  isMobile,
  extended,

  renderActions,
  onUserClick,
  onTagClick,
  onRequestMore,
  onCopyDiscordClick,
  onRequestEditLfg,
}) => {
  const isMobileProfile = isMobile && hideUser;

  const data = useMemo(() => {
    const usersWithProfiles = users?.data.filter(user => user.lfg?.length);

    return usersWithProfiles;
  }, [users]);

  const renderList = () => {
    return (
      <div
        className={cx(css.listContainer, {
          [css.profileListContainer]: isMobileProfile,
        })}
      >
        {data?.flatMap(user => {
          return (
            user.lfg
              ?.filter(profile => profile.type === api.LfgType.Profile)
              ?.map(profile => (
                <GameProfileCard
                  key={profile.id}
                  extended={extended}
                  hideUser={hideUser}
                  isMobile={isMobile}
                  profile={profile}
                  renderActions={renderActions}
                  selectedTags={selectedTags}
                  user={user}
                  onCopyDiscordClick={onCopyDiscordClick}
                  onRequestEditLfg={onRequestEditLfg}
                  onTagClick={onTagClick}
                  onUserClick={onUserClick}
                />
              )) || []
          );
        })}
      </div>
    );
  };

  if (isLoading) {
    return <Layout.Loading />;
  }

  if (error) {
    return <Error subtext={JSON.stringify(error)}>Error</Error>;
  }

  if (users && data) {
    if (data.length === 0) {
      return renderEmpty;
    }

    if (users.data.length === 1) {
      const user = users.data[0];

      if (!user.lfg?.length) {
        return renderEmpty;
      }
    }

    return (
      <Pagination
        count={users.count}
        hasMore={users.after}
        limit={users.limit}
        onRequestMore={onRequestMore}
      >
        {renderList()}
      </Pagination>
    );
  }

  return null;
};
