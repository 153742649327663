import React, { useCallback } from 'react';

import { api } from 'api';

import { AvatarUser } from 'components/Avatar';
import { Icon } from 'components/Icons';
import { Discord2, Copy } from 'components/Icons/Icons';
import { Text } from 'components/Text';
import { useToasts } from 'components/Toast';
import { LinkUser } from 'components/Utils/Utils';

import { useText } from 'text';
import { copyToClipboard } from 'utils/main';

import css from './EventWrapperAuthor.module.css';

interface EventWrapperAuthorUserProps {
  user: api.User;
  description?: string;
  renderActions?: () => React.ReactElement | null;
  onAuthorClick?: (event: React.MouseEvent) => void;

  onCopyDiscord?: (event: React.MouseEvent) => void;
}

export const EventWrapperAuthorUser = React.memo<EventWrapperAuthorUserProps>(
  ({ user, onAuthorClick, onCopyDiscord, renderActions }) => {
    const toasts = useToasts();
    const text = useText(state => state.userGames.card.toastLoginCopied);

    const handleCopyDiscord = useCallback(
      (event: React.MouseEvent) => {
        if (!user.discord?.username) {
          return;
        }

        copyToClipboard(user.discord.username);

        if (onCopyDiscord) {
          onCopyDiscord(event);
        }

        toasts.alert({ type: 'info', message: text });
      },
      [user.discord?.username, onCopyDiscord, text],
    );

    const renderDetails = () => {
      return (
        <section className={css.thumbnailDetailsContainer}>
          {user.discord?.username ? (
            <>
              <object className={css.separator} />

              <button className={css.discordContainer} onClick={handleCopyDiscord}>
                <Discord2 className={css.discordIcon} height={14} width={14} />
                <Text.R3 className={css.discordText}>
                  {user.discord?.username}
                </Text.R3>
                <Copy className={css.discordIcon} height={10} width={10} />
              </button>
            </>
          ) : null}
        </section>
      );
    };

    return (
      <section className={css.container}>
        <AvatarUser
          className={css.avatarImg}
          user={user}
          onLinkClick={onAuthorClick}
        />

        <article className={css.thumbnailContainer}>
          <LinkUser
            className={css.usernameContainer}
            username={user.username}
            onClick={onAuthorClick}
          >
            <Text.R3 semibold>@{user.username}</Text.R3>
            {user.roles?.includes(api.UserRole.Streamer) ? (
              <Icon.StreamerCheck className={css.streamerCheck} />
            ) : null}
          </LinkUser>

          {renderDetails()}
        </article>

        {renderActions ? (
          <div className={css.actionsContainer}>{renderActions()}</div>
        ) : null}
      </section>
    );
  },
);
