import React, { useMemo } from 'react';

import { api } from 'api';

import { CardParam, CardParams, CardParamsTable } from 'components/Card';
import { LolChampionAvatarList } from 'components/GameLol/LolChampionAvatarList/LolChampionAvatarList';
import { LolRankParam } from 'components/GameLol/LolRankParam';
import { LolWinrateParam } from 'components/GameLol/LolWinrateParam';
import { MobileProfileCardShadow } from 'components/ProfileCardList/ProfileInfoGames/ProfileInfoWow';
import { useText, useTranslations } from 'text';
import { filterGames } from 'utils/main';

import { GameProfileCardTemplate } from '../Template';

import css from './LolProfileCard.module.css';

import type { GameProfileCardProps } from '../[Game]ProfileCard';

export const LolProfileCard: React.FC<GameProfileCardProps> = ({ ...props }) => {
  const text = useText(state => state.gameData.lol);
  const translation = useTranslations();

  const profile = props.profile;
  const games = filterGames<api.GameLol>(
    api.GameId.LeagueOfLegends,
    profile?.games || [],
  );

  const isMobileProfile = props.isMobile && props.hideUser;

  const params = useMemo<CardParam[]>(() => {
    if (games.length === 0) {
      return [];
    }
    const game = games[0];
    const params: CardParam[] = [];

    if (profile?.lol?.region) {
      params.push({
        title: text.region.title,
        value: translation.lol.region(profile.lol.region, true),
      });
    }

    if (game.kills || game.deaths || game.assists) {
      params.push({
        title: text.kda,
        value: [
          (game.kills || 0).toFixed(1),
          (game.deaths || 0).toFixed(1),
          (game.assists || 0).toFixed(1),
        ],
      });
    }

    return params;
  }, [games, profile?.lol?.region]);

  const bottomParams = useMemo<CardParam[]>(() => {
    if (games.length === 0) {
      return [];
    }
    const game = games[0];
    const params: CardParam[] = [];

    if (game.soloRank !== api.LolRank.None) {
      params.push({
        title: 'SoloQ',
        value: <LolRankParam rank={game.soloRank} />,
      });
    }
    if (game.flexRank !== api.LolRank.None) {
      params.push({
        title: 'FlexQ',
        value: <LolRankParam rank={game.flexRank} />,
      });
    }

    params.push({
      title: text.winRate.title.full,
      value: <LolWinrateParam game={game} />,
    });

    return params;
  }, [games]);

  const renderPlayerInfo = (): React.ReactNode => {
    if (!params.length) {
      return null;
    }

    if (isMobileProfile) {
      return (
        <div className={css.lolProfileCard}>
          <CardParams leftPadding scrolled={false} values={params} />
        </div>
      );
    }

    if (!games.length) {
      return null;
    }

    const game = games[0];

    return (
      <div className={css.lolProfileCard}>
        <LolChampionAvatarList
          champions={game.champions}
          role={game.preferredLine || game.preferredLine2}
        />
        {params.length > 0 && (
          <CardParamsTable leftPadding scrolled={false} values={params} />
        )}
        <MobileProfileCardShadow isMobileProfile={isMobileProfile} />
      </div>
    );
  };

  const bottomContent = (): React.ReactNode => {
    if (!bottomParams.length) {
      return null;
    }

    if (!games.length) {
      return null;
    }
    const game = games[0];

    return (
      <div className={css.lolProfileCard}>
        <LolChampionAvatarList
          champions={game.champions}
          role={game.preferredLine || game.preferredLine2}
        />
        {params.length > 0 && (
          <CardParamsTable leftPadding scrolled={false} values={bottomParams} />
        )}
      </div>
    );
  };

  const isEmptyProfile = [...params, ...bottomParams].length === 0 && !games.length;

  return (
    <GameProfileCardTemplate
      {...props}
      bottomContent={bottomContent()}
      isEmptyCard={isEmptyProfile}
      topContent={renderPlayerInfo()}
    />
  );
};
