import React, { useState } from 'react';

import cx from 'classnames';

import { Icon } from 'components/Icons';

import { LoaderBouncing } from 'components/Loaders';

import { getFaviconUrl } from 'components/SlideoverSettings/SlideLinks/UserLinkForm';

import { useText } from 'text';

import css from './EventAction.module.css';

type ActionColor = 'default' | 'green-20' | 'yellow-20';

interface EventActionProps {
  text: string;
  Icon: Icon.IconType;
  color?: ActionColor;
  iconColor?: string;

  disabled?: boolean;
  onClick?: (event: React.MouseEvent) => void;
  linkUrl?: string;
}
export const EventAction = React.memo<EventActionProps>(
  ({ text, Icon, color, iconColor, disabled = false, linkUrl, onClick }) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const eventText = useText(state => state.event);

    const handleClick = async (event: React.MouseEvent): Promise<void> => {
      if (isLoading || !onClick) {
        return;
      }

      setIsLoading(true);
      try {
        await onClick(event);
      } finally {
        setIsLoading(false);
      }
    };

    if (linkUrl) {
      const imgUrl = getFaviconUrl(linkUrl);

      return (
        <a
          className={cx(css.container, {
            [css.green20Container]: color === 'green-20',
            [css.yellow20Container]: color === 'yellow-20',
          })}
          href={linkUrl}
          rel="noreferrer"
          target="_blank"
        >
          <img className={css.icon} src={imgUrl} />
          <span className={css.text}>{eventText.actions.link.open}</span>
        </a>
      );
    }

    return (
      <button
        className={cx(css.container, {
          [css.green20Container]: color === 'green-20',
          [css.yellow20Container]: color === 'yellow-20',
        })}
        disabled={disabled}
        onClick={handleClick}
      >
        {!isLoading ? (
          <Icon
            className={css.icon}
            style={
              iconColor
                ? {
                    fill: iconColor,
                    stroke: iconColor,
                  }
                : undefined
            }
          />
        ) : (
          <LoaderBouncing
            className={css.loaderContainer}
            color="var(--icon-color)"
            size={20}
          />
        )}

        <span className={css.text}>{text}</span>
      </button>
    );
  },
);
