import React from 'react';

import css from './FormGrid.module.css';
import { getGridSizeStyle } from './helpers';

interface GridGapProps {
  gridSize: number;
}

export const GridGap = React.memo<GridGapProps>(({ gridSize }) => {
  return <div className={css.dummyContainer} style={getGridSizeStyle(gridSize)} />;
});
