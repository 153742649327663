import React from 'react';

import cx from 'classnames';

import { api } from '../../../api';
import { LostArkClassIcon } from '../../Icons__deprecated';

import { useText } from '../../Language';
import { Text } from '../../Text';

import css from './ProfileInfoGames.module.css';
import { MobileProfileCardShadow } from './ProfileInfoWow';

interface LostArkCharactersTableProps {
  games: api.LostArk[];
  isMobileProfile?: boolean;
}
export const LostArkCharactersTable = React.memo<LostArkCharactersTableProps>(
  ({ games, isMobileProfile }) => {
    const text = useText(state => state.userSearch.gameCards);

    return (
      <div>
        <Text.R4 className={css.title}>{text.characters}</Text.R4>

        <div className={css.lostArkGamesList}>
          {games.map((game, key) => (
            <LostArkGameCard key={key} game={game} />
          ))}
        </div>
      </div>
    );
  },
);

interface LostArkGameCardProps {
  game: api.LostArk;
  dimmed?: boolean;
}

const LostArkGameCard: React.FC<LostArkGameCardProps> = ({ game, dimmed }) => {
  return (
    <div className={css.lostArkGame} style={dimmed ? { opacity: 0.5 } : {}}>
      {game.class ? (
        <LostArkClassIcon
          className={css.lostArkGameAvatar}
          lostArkClass={game.class}
          size={32}
        />
      ) : null}
      <div className={css.lostArkGameInfo}>
        <Text.R3 semibold>{game.alias}</Text.R3>
        <div className={css.lostArkGameInfoLine}>
          {game.class ? (
            <>
              <Text.R4 className={css.lostArkGameInfoClass}>{game.class}</Text.R4>
              <div className={css.dot} />
            </>
          ) : null}
          <Text.R4 className={css.lostArkGameInfoClass}>{game.gearScore}</Text.R4>
        </div>
      </div>
    </div>
  );
};

export const LostArkGameCardTable: React.FC<LostArkGameCardProps> = ({
  game,
  dimmed,
}) => {
  return (
    <tr style={dimmed ? { opacity: 0.5 } : {}}>
      <td className={css.mainColumn}>
        {game.class ? (
          <div className={css.lostArkGameAvatar}>
            <LostArkClassIcon
              className={css.classIconTable}
              lostArkClass={game.class}
              size={24}
            />
          </div>
        ) : null}

        <Text.R3 semibold className={cx(css.lostArkTableData, css.whiteText)}>
          {game.alias}
        </Text.R3>
      </td>
      <TextTableData className={css.whiteText}>{game.gearScore || 0}</TextTableData>
      <TextTableData>{game.class || '–'}</TextTableData>
    </tr>
  );
};

interface TextTableDataProps {
  children: string | number;
  style?: React.CSSProperties;
  className?: string;
}

const TextTableData = React.memo<TextTableDataProps>(
  ({ children, style, className }) => {
    return (
      <td>
        <Text.R3
          semibold
          className={cx(css.lostArkTableData, className)}
          style={style}
        >
          {children}
        </Text.R3>
      </td>
    );
  },
);
